import React, { useState }  from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form'
import {
    Row,
    Col,
    Label,
    Button,
    ButtonGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText
 } from 'reactstrap';
import { PdfLayout } from '../../../../../models';

const PdfPageOptions = ({ page, pageIndex, setPdfPageSize, onDelete, onDuplicate }) => {
    const { t } = useTranslation();

    const { isMobile }                = useSelector(state => state.ui);
    const [layoutType, setLayoutType] = useState(PdfLayout.LAYOUT_TYPE_TICKET);

    // page remove confirmation
    const [confirmationIsVisible, setConfirmationVisible] = useState(false);

    const setWidth  = width => { page.dimensions.width = width; }
    const setHeight = height => { page.dimensions.height = height; }

    const removePage = () => {
        onDelete();
        setConfirmationVisible(false);
    }

    if (!page)
        return null;

    return (
        <div className="PdfPageOptions wrapper-1">
            <Row>
                <Col>
                    <Label>{t('layouts.layout.dimensions')}</Label>
                    <Field
                        data-for={`layouts.size`}
                        component="select"
                        className="form-control w-100"
                        name={`pages[${pageIndex}].size`}
                        value={ page.size }
                        onChange= { (event) => setPdfPageSize({ size: event.target.value })}
                        style={{width : '150px', height: '34px', marginRight: '10px'}}
                    >
                        { PdfLayout.sizes().map((data) => {
                              return (
                                <option key={ data.name }>
                                  {data.name}
                                </option>
                              )
                        })}
                    </Field>
                </Col>
            </Row>
            {page.size !== "Custom" && (
                <>
                    <Field
                        component="input"
                        min = { 0 }
                        type="hidden"
                        name={`pages[${pageIndex}].dimensions.width`}
                        value={ PdfLayout.sizes(page.size).width }
                    />
                    <Field
                        component="input"
                        min = { 0 }
                        type="hidden"
                        name={`pages[${pageIndex}].dimensions.height`}
                        value={ PdfLayout.sizes(page.size).height }
                    />
                </>
            )}
            {page.size == "Custom" && (
                <>
                    <Row>
                        <Col>
                            <InputGroup>
                                <Field
                                    component="input"
                                    type="number"
                                    className="form-control"
                                    name={`pages[${pageIndex}].dimensions.width`}
                                    onChange={e => { setPdfPageSize({ width: e.target.value }); }}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>cm</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup>
                                <Field
                                    component="input"
                                    type="number"
                                    className="form-control"
                                    name={`pages[${pageIndex}].dimensions.height`}
                                    onChange={e => { setPdfPageSize({ height: e.target.value }); }}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>cm</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </Row>
                </>
            )}
            <Row>
                <Col className={"text-" + (isMobile ? "center m-1": "left")}>
                    <ButtonGroup>
                        <Button color="danger" onClick={() => setConfirmationVisible(true)}>
                            <span>
                                <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                            </span>
                        </Button>
                    </ButtonGroup>
                </Col>
                <Col className={"text-" + (isMobile ? "center m-1": "right")}>
                    <ButtonGroup>
                        <Button onClick={ onDuplicate } color='info' size="sm">
                            <i className="fa fa-copy"></i> {t("common.duplicate")}
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            {confirmationIsVisible && (
            <Row>
                <Col>
                    <b>{t('common.are_you_sure')}</b>&nbsp;
                    <ButtonGroup>
                        <Button onClick={() => setConfirmationVisible(false)} color='info' size="sm">
                            <i className="fa fa-times"></i> {t("common.no")}
                        </Button>
                        <Button type="submit" color="danger" onClick={removePage}>
                            <span>
                                <i className="fa fa-trash"></i>&nbsp;{t("common.yes")}
                            </span>
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            )}
        </div>
    );
}

export default PdfPageOptions;
