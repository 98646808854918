import BaseModel from './BaseModel';

/** Class representing a tkt instance. */
class TktInstance extends BaseModel {
    hasModule = (module) => {
        return this.options && this.options.modules &&
           this.options.modules.includes(module);
    }

    hasEventivalIntegration = (setting) => {
        let eventival = setting?.integrations?.eventival ? setting.integrations.eventival : null;
        if (eventival != null && eventival.api_key.length > 0 && eventival.name.length > 0 && eventival.year.length > 0) {
            return true;
        }
        return false;
    }

    hasJsonIntegration = (setting) => {
        return (
            !!setting?.integrations?.json?.import?.screenings?.enabled &&
            setting.integrations.json.places_key?.length > 0
        );
    }
}

export default TktInstance;
