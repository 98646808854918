import BaseModel from './BaseModel';
import { v4 } from 'uuid';
import { PdfLayoutWidget } from './PdfLayoutWidgets';
import { Utils, Screen } from '../services';
import {
    CustomTextWidget,
    EnvironmentInfoWidget,
    FrameWidget,
    ImageWidget,
    LibraryImageWidget,
    LineWidget,
    LinkWidget,
    OwnerContactImageWidget,
    QrcodeWidget,
    OwnerInfoWidget,
    ScreeningInfoWidget,
    TicketInfoWidget,
    CustomerInfoWidget,
    TickettypeInfoWidget,
} from './PdfLayoutWidgets';
import moment from "moment";
import _ from 'lodash';

/** Class representing a PDF layout. */
class PdfLayout extends BaseModel {
    /* Layout types */
    // keep those constants synced with Engine and Eshop
    static LAYOUT_TYPE_TICKET          = 'ticket';
    static LAYOUT_TYPE_TICKET_BOOKINGS = 'ticket-bookings';
    static LAYOUT_TYPE_CART_RECEIPT    = 'cart-receipt';
    static LAYOUT_TYPE_CLOSING         = 'closing';
    static LAYOUT_TYPE_BILL            = 'bill';
    static LAYOUT_TYPE_ORDER           = 'order';

    /* Layout targets */
    // keep those constants synced with Engine and Eshop
    static LAYOUT_TARGET_PAPER   = 'paper';
    static LAYOUT_TARGET_THERMAL = 'thermal';
    static LAYOUT_TARGET_CARD    = 'card';
    static LAYOUT_TARGET_OTHER   = 'other';

    /* Widget types */
    static TEXT                = 'text';
    static IMAGE               = 'image';
    static SHAPE               = 'shape';
    static CUSTOM              = 'custom';
    static FRAME               = 'frame';
    static LINE                = 'line';
    static QRCODE              = 'qrcode';
    static LIBRARY_IMAGE       = 'library';
    static LINK                = 'link';
    static OWNER_CONTACT_IMAGE = 'owner_contact';
    static OWNER_INFO          = 'owner_info';
    static SCREENING_INFO      = 'screening_info';
    static TICKET_INFO         = 'ticket_info';
    static TICKETTYPE_INFO     = 'tickettype_info';
    static CUSTOMER_INFO       = 'customer_info';
    static ENVIRONMENT_INFO    = 'environment_info';

    /* Options types */
    static OPTION_X                 = 'x';
    static OPTION_Y                 = 'y';
    static OPTION_Z                 = 'z';
    static OPTION_WIDTH             = 'width';
    static OPTION_HEIGHT            = 'height';
    static OPTION_LOCK_RATIO        = 'lock-ratio';
    static OPTION_ROTATION          = 'rotation';
    static OPTION_COLOR             = 'color';
    static OPTION_BACKGROUND_COLOR  = 'background-color';
    static OPTION_TEXT              = 'text';
    static OPTION_TEXT_ALIGNMENT    = 'text-alignment';
    static OPTION_TEXT_DECORATION   = 'text-decoration';
    static OPTION_FONT_FAMILY       = 'font-family';
    static OPTION_FONT_SIZE         = 'font-size';
    static OPTION_LINE_HEIGHT       = 'line-height';
    static OPTION_CONTACT_FIELD     = 'contact-field';
    static OPTION_SCREENING_FIELD   = 'screening-field';
    static OPTION_TICKET_FIELD      = 'ticket-field';
    static OPTION_TICKETTYPE_FIELD  = 'tickettype-field';
    static OPTION_CUSTOMER_FIELD    = 'customer-field';
    static OPTION_ENVIRONMENT_FIELD = 'environment-field';
    static OPTION_ALIGNMENT         = 'alignment';
    static OPTION_PADDING           = 'padding';
    static OPTION_OBJECT_FIT        = 'object-fit';
    static OPTION_DATE_FORMAT       = 'date-format';
    static OPTION_DATE_OFFSET       = 'date-offset';
    static OPTION_IMAGE             = 'image';
    static OPTION_IMAGE_TYPE        = 'image-type';
    static OPTION_BORDER_STYLE      = 'border-style';
    static OPTION_BORDER_WIDTH      = 'border-width';
    static OPTION_BORDER_COLOR      = 'border-color';
    static OPTION_BORDER_RADIUS     = 'border-radius';
    static OPTION_LINK              = 'link';
    static OPTION_TEXT_TRANSFORM    = 'text-transform';

    static types = () => [
        PdfLayout.LAYOUT_TYPE_TICKET,
        PdfLayout.LAYOUT_TYPE_TICKET_BOOKINGS,
        PdfLayout.LAYOUT_TYPE_CART_RECEIPT,
        PdfLayout.LAYOUT_TYPE_CLOSING,
        PdfLayout.LAYOUT_TYPE_BILL,
        PdfLayout.LAYOUT_TYPE_ORDER
    ];

    static targets = () => [
        PdfLayout.LAYOUT_TARGET_PAPER,
        PdfLayout.LAYOUT_TARGET_THERMAL,
        PdfLayout.LAYOUT_TARGET_CARD,
        PdfLayout.LAYOUT_TARGET_OTHER
    ];

    static sizes = (size) => {
        // dimensions are in centimeters
        const sizes = [
            { "name": "A4", "width": 21, "height": 29.7 },
            { "name": "A4_Landscape", "width": 29.7, "height": 21 },
            { "name": "Thermal", "width": 7.62, "height": 10.16 },
            { "name": "Thermal_Landscape", "width": 10.16, "height": 7.62 },
            { "name": "Custom", "width": 21, "height": 29.7 },
            { "name": "A0", "width": 84.1, "height": 118.9 },
            { "name": "A1", "width": 59.4, "height": 84.1 },
            { "name": "A2", "width": 42, "height": 59.4 },
            { "name": "A3", "width": 29.7, "height": 42 },
            { "name": "A5", "width": 14.8, "height": 21 },
            { "name": "A6", "width": 10.5, "height": 14.8 },
            { "name": "A7", "width": 7.4, "height": 10.5 },
            { "name": "A8", "width": 5.2, "height": 7.4 },
            { "name": "A9", "width": 3.7, "height": 5.2 },
            { "name": "A10", "width": 2.6, "height": 3.7 },
            { "name": "B0", "width": 100, "height": 141.4 },
            { "name": "B1", "width": 70.7, "height": 100 },
            { "name": "B2", "width": 50, "height": 70.7 },
            { "name": "B3", "width": 35.3, "height": 50 },
            { "name": "B4", "width": 25, "height": 35.3 },
            { "name": "B5", "width": 17.6, "height": 25 },
            { "name": "B6", "width": 12.5, "height": 17.6 },
            { "name": "B7", "width": 8.8, "height": 12.5 },
            { "name": "B8", "width": 6.2, "height": 8.8 },
            { "name": "B9", "width": 4.4, "height": 6.2 },
            { "name": "B10", "width": 3.1, "height": 4.4 },
            { "name": "C0", "width": 91.7, "height": 129.7 },
            { "name": "C1", "width": 64.8, "height": 91.7 },
            { "name": "C2", "width": 45.8, "height": 64.8 },
            { "name": "C3", "width": 32.4, "height": 45.8 },
            { "name": "C4", "width": 22.9, "height": 32.4 },
            { "name": "C5", "width": 16.2, "height": 22.9 },
            { "name": "C6", "width": 11.4, "height": 16.2 },
            { "name": "C7", "width": 8.1, "height": 11.4 },
            { "name": "C8", "width": 5.7, "height": 8.1 },
            { "name": "C9", "width": 4, "height": 5.7 },
            { "name": "C10", "width": 2.8, "height": 4 },
        ];

        return size ? sizes.find(s => s.name === size) : sizes;
    };

    static contactFields = () => {
        return [
            'company', 'title', 'firstname', 'lastname', 'fullname', 'fullname-reversed',
            'email', 'age', 'birthdate', 'sex',
            'phone', 'cellphone', 'language',
            'address.street', 'address.city', 'address.zip', 'address.country',
        ];
    };

    static screeningFields = () => {
        return [ 'title',
                 'venue', 'place', 'address', 'zip', 'city', 'state', 'country',
                 'start_at', 'stop_at', 'start_at_stop_at'
               ];
    };

    static ticketFields = () => {
        return [
            'ticket_id', 'ticket_id_only', 'ticket_id_festivalscope', 'uuid', 'activated_at',
            'activated_pricing_name', 'activated_pricing_price', 'vat',
            'activated_pricing_description',
            'first_window', 'last_window', 'first_window_last_window'
        ];
    };

    static tickettypeFields = () => {
        return [ 'name', 'description' ];
    };

    static customerFields = () => {
        return [ 'name', 'name_short', 'address', 'phone', 'email', 'vat'];
    };

    static environmentFields = () => {
        return [ 'printer', 'salepoint', 'printed_at' ];
    };

    static imageTypes = () => {
        return Utils.image_types('setting');
    };

    static objectFitValues = () => {
        return [
            'none',
            /*'fill', fill is not supported by PDFKit */
            'cover',
            'contain'
        ];
    };

    static borderStyleValues = () => {
        return [
            'solid',
            'dashed',
            /*'dotted', dotted is not supported by PDFKit */
        ];
    };

    static textDecorationValues = () => {
        return [
            'none',
            'underline',
            'line-through',
        ];
    };

    static textTransformValues = () => {
        return [
            'none',
            'capitalize',
            'uppercase',
            'lowercase'
        ];
    };

    static pdfFontToCssStyle = (pdfFontFamilyName, pdfFontFamilyVariant) => {
        let fontFamily = pdfFontFamilyName;
        switch (pdfFontFamilyVariant) {
            case 'bold':
                fontFamily += '-Bold';
                break;
            case 'italic':
                fontFamily += '-Italic';
                break;
            case 'boldItalic':
                fontFamily += '-BoldItalic';
                break;
        }

        const fontWeight = 'normal';
        const fontStyle  = 'normal';

        return { fontFamily, fontWeight, fontStyle };
    }

    static dateFormats = () => [
        "LT","LTS","L","l","LL",
        "ll","LLL","lll","LLLL","llll",
        "dddd DD.MM", "dddd DD.MM.YYYY",
        "dddd Do MMM", "dddd Do MMMM",
        "dddd Do MMM YYYY", "dddd Do MMMM YYYY",
        "ddd DD.MM", "ddd DD.MM.YYYY",
        "ddd Do MMM", "ddd Do MMMM",
        "ddd Do MMM YYYY", "ddd Do MMMM YYYY",
        "midnight_hack",
    ];

    /**
     * @param {string} date
     * @param {string} midnight | à minuit
     * @param midnight15 | à minuit et quart
     * @param midnight30 | à minuit et demi
     * @returns {string}
     */
    static midnightHack = (date, midnight, midnight15, midnight30) => {
        let _date   = moment(date, "DD.MM.YYYY HH:mm");
        let hours   = parseInt(_date.format("H"), 10);
        let minutes = parseInt(_date.format("mm"), 10);

        // Subtract 1 day from the date (niff)
        _date = _date.subtract(1, "days");

        if (hours !== 0)
            return _date.format("DD/MM/YYYY H[h]mm");

        if (minutes === 30)
            return _date.format(`DD/MM/YYYY [${midnight30}]`);

        if (minutes === 15)
            return _date.format(`DD/MM/YYYY [${midnight15}]`);

        return _date.format(`DD/MM/YYYY [${midnight}] mm`);
    };

    static createWidget(properties) {
        switch (properties.type) {
            case PdfLayout.SHAPE:
                switch (properties.subType) {
                    case PdfLayout.FRAME:
                        return new FrameWidget(properties);
                    case PdfLayout.LINE:
                        return new LineWidget(properties);
                }
                break;
            case PdfLayout.IMAGE:
                switch (properties.subType) {
                    case PdfLayout.CUSTOM:
                        return new ImageWidget(properties);
                    case PdfLayout.QRCODE:
                        return new QrcodeWidget(properties);
                    case PdfLayout.LIBRARY_IMAGE:
                        return new LibraryImageWidget(properties);
                    case PdfLayout.OWNER_CONTACT_IMAGE:
                        return new OwnerContactImageWidget(properties);
                }
                break;
            case PdfLayout.TEXT:
                switch (properties.subType) {
                    case PdfLayout.CUSTOM:
                        return new CustomTextWidget(properties);
                    case PdfLayout.LINK:
                        return new LinkWidget(properties);
                    case PdfLayout.OWNER_INFO:
                        return new OwnerInfoWidget(properties);
                    case PdfLayout.SCREENING_INFO:
                        return new ScreeningInfoWidget(properties);
                    case PdfLayout.TICKET_INFO:
                        return new TicketInfoWidget(properties);
                    case PdfLayout.TICKETTYPE_INFO:
                        return new TickettypeInfoWidget(properties);
                    case PdfLayout.CUSTOMER_INFO:
                        return new CustomerInfoWidget(properties);
                    case PdfLayout.ENVIRONMENT_INFO:
                        return new EnvironmentInfoWidget(properties);
                }
                break;
        }

        return null;
    }

    static getAvailableWidgets(type) {
        const availableWidgets = {
            [PdfLayout.SHAPE]: [
                new FrameWidget(),
                new LineWidget(),
            ],
            [PdfLayout.IMAGE]: [
                new LibraryImageWidget(),
                new ImageWidget(),
                new OwnerContactImageWidget(),
                new QrcodeWidget(),
            ],
            [PdfLayout.TEXT]: [
                new OwnerInfoWidget(),
                new TicketInfoWidget(),
                new TickettypeInfoWidget(),
                new ScreeningInfoWidget(),
                new CustomerInfoWidget(),
                new EnvironmentInfoWidget(),
                new CustomTextWidget(),
                new LinkWidget(),
            ]
        };

        if (type)
            return availableWidgets[type];

        return availableWidgets;
    }

    static defaultLayout() {
        return {
            _id: v4(),
            type: PdfLayout.LAYOUT_TYPE_TICKET,
            target: PdfLayout.LAYOUT_TARGET_PAPER,
            name: {
                fr: 'PDF Layout',
                en: 'PDF Layout',
                de: 'PDF Layout'
            },
            pages: [{
                size: 'A4',
                dimensions: { width: 21, height: 29.7 },
            }],
            widgets: [
                new FrameWidget({
                    page: 0,
                    dimensions: {width: Screen.pxToCm(216), height: Screen.pxToCm(288)}
                }).toPlainObject(),
                new QrcodeWidget({
                    page: 0,
                    position: { x: Screen.pxToCm(174), y: Screen.pxToCm(245), z: 0 },
                    dimensions: { width: Screen.pxToCm(60), height: Screen.pxToCm(60)},
                }).toPlainObject(),
                new CustomTextWidget({
                    page: 0,
                    position: { x: Screen.pxToCm(30), y: Screen.pxToCm(30), z: 0 },
                    dimensions: { width: Screen.pxToCm(100), height: Screen.pxToCm(30)},
                }).toPlainObject()
            ]
        };
    }

    constructor(properties) {
        super(properties);

        this.widgets = (this.widgets || []).map(w => new PdfLayoutWidget(w));
    }

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = _.cloneDeep(this);

        prepared.pages = (prepared.pages || []).map(page => ({
            ...page,
            dimensions: {
                width: parseFloat(page.dimensions.width),
                height: parseFloat(page.dimensions.height)
            }
        }));

        prepared.widgets = (prepared.widgets || []).map(widget => ({
            ...widget,
            page: !(['all', 'odd', 'even'].includes(widget.page)) ?
                parseInt(widget.page, 10) :
                widget.page
        }));

        return prepared;
    }

    /**
     * Prepare this object for duplicate.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForDuplicate() {
        const prepared = _.cloneDeep(this);
        prepared._id = v4();

        return prepared;
    }

    /**
     * Check if a lyout has any shortcode of a specific type
     *
     * @param {String} type
     * @param {String} lang
     * @return {Boolean}
     */
    hasShortcodeOfType(type, lang) {
        let result = false;

        const regexp = new RegExp(`\\[${type}[\.\\]]{1}`, 'g');
        this.widgets.forEach(widget => {
            const text = widget.options?.text ? widget.options?.text[lang] : '';
            result = result || regexp.test(text);
        });

        return result;
    }
}

/**
 * Get the fields to get server side when we
 * ask for a listing
 */
PdfLayout.getListingFields = () => ['_id', 'name', 'layout_type', 'type', 'target'];

export default PdfLayout;
