import BaseModel from './BaseModel';
import moment from 'moment';

/** Class representing a tag. */
class Log extends BaseModel {
    static BOOKING_CONFIRM_TYPE        = 'confirm_booking';
    static UNBOOK_TYPE                 = 'unbook';
    static TICKET_ACTIVATE_TYPE        = 'activate';
    static TICKET_DELETE_TYPE          = 'delete';
    static TICKET_BLOCK_TYPE           = 'block';
    static TICKET_UNBLOCK_TYPE         = 'unblock';
    static TICKET_CHECKIN_TYPE         = 'checkin';
    static TICKET_CHECKOUT_TYPE        = 'checkout';
    static TICKET_CHANGE_TYPE_TYPE     = 'change_type';
    static EMAIL_SENT_TYPE             = 'email_sent';
    static EMAIL_ERROR_TYPE            = 'email_error';
    static EMAIL_OPENED_TYPE           = 'email_opened';
    static SCREENINGS_IMPORT           = 'screenings_import';
    static TICKETS_IMPORT              = 'tickets_import';
    static PLACES_IMPORT               = 'places_import';

    constructor(properties) {
        super(properties || {});

        if (this.created_at)
            this.created_at = moment(this.created_at);

        if (this.inserted)
            this.inserted = (this.inserted || []).map(inserted => {
                if ('start_at' in inserted)
                    inserted.start_at = moment(inserted.start_at);
                if ('stop_at' in inserted)
                    inserted.stop_at = moment(inserted.stop_at);

                return inserted;
            });
            this.updated = (this.updated || []).map(updated => {
                if ('start_at' in updated)
                    updated.start_at = moment(updated.start_at);
                if ('stop_at' in updated)
                    updated.stop_at = moment(updated.stop_at);

                return updated;
            });
            this.invalid = (this.invalid || []).map(invalid => {
                if ('start_at' in invalid)
                    invalid.start_at = moment(invalid.start_at);
                if ('stop_at' in invalid)
                    invalid.stop_at = moment(invalid.stop_at);

                return invalid;
            });
    }
}

export default Log;
