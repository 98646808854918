import React,{ useEffect } from 'react';
import { connect, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { Setting } from '../../../models';
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import SettingField from '../../../components/SettingField/SettingField';

import './SettingForm.css';

let SettingForm = props => {
    const {
        handleSubmit, submitting, isLoading,
        setting, field, places, overrides,
        tickettypes, salepoints, articles,
        articleCategories, postCategories,
        layouts, onDeleteFromSalePoint
    } = props;
    const { isMobile } = useSelector(state => state.ui);
    const { t }        = useTranslation();
    const handle       = useFullScreenHandle();

    useEffect(() => {
        const handleKeySubmit = (evt) => {
            if((evt.ctrlKey && evt.key == "s") || (evt.ctrlKey && evt.code == "KeyS")) {
                evt.stopPropagation();
                evt.preventDefault();
                handleSubmit();
            }
        };

        window.addEventListener('keydown', handleKeySubmit);

        return () => {
            window.removeEventListener('keydown', handleKeySubmit);
        };
      }, []);

    if (!setting)
        return null;

    return (
        <form className="SettingForm" onSubmit={handleSubmit}>
            <FullScreen handle={ handle }>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                    <CardTitle>
                                        <i className={ `fa fa-${Setting.propertyIcon(field, 'gear')}` }></i>&nbsp;{t(`settings.${field}.title`)}
                                        <ButtonGroup className={!isMobile ? "pull-right" : "col-12 p-0"}>
                                            <Button onClick={handleSubmit} type="button" color="success" disabled={submitting || isLoading}>
                                                {isLoading ?
                                                    <span>
                                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                    </span>
                                                    :
                                                    <span>
                                                        <i className="fa fa-save"></i>&nbsp;
                                                        {t("common.save")}
                                                    </span>
                                                }
                                            </Button>
                                            { !handle.active && (
                                                <Button  className={isMobile ? "col-3" : ""} onClick={ handle.enter } type="button" color="info">
                                                    <i className="fa fa-arrows-alt"></i>
                                                </Button>
                                            )}
                                            { handle.active && (
                                                <Button onClick={ handle.exit } type="button" color="danger">
                                                    <i className="fa fa-times"></i>
                                                </Button>
                                            )}
                                        </ButtonGroup>
                                    </CardTitle>
                                    <CardText tag="div">
                                        <SettingField
                                            setting={ setting }
                                            name={ field }
                                            overrides={ overrides }
                                            tickettypes={ tickettypes }
                                            salepoints={ salepoints }
                                            articles={ articles }
                                            articleCategories={ articleCategories }
                                            postCategories={ postCategories }
                                            places={ places }
                                            layouts={ layouts }
                                            onDeleteFromSalePoint={onDeleteFromSalePoint}
                                        />
                                        <Button onClick={handleSubmit} type="button" color="success" disabled={submitting || isLoading} className={!isMobile ? "pull-right mt-2" : "col-12 mt-2"}>
                                            {isLoading ?
                                                <span>
                                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                </span>
                                                :
                                                <span>
                                                    <i className="fa fa-save"></i>&nbsp;
                                                    {t("common.save")}
                                                </span>
                                            }
                                        </Button>
                                    </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </FullScreen>
            <br/>
        </form>
    );
}


// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
SettingForm = reduxForm({
  form: 'settingForm', // a unique identifier for this form
  enableReinitialize: true
})(SettingForm);

SettingForm = connect( state => ({}), {})(SettingForm)

export default SettingForm;
