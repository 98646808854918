import React from "react";
import { Button } from 'reactstrap';

const OverviewSeat = ({
    gridCells,
    children,
    draggedCells,
    index,
    color,
    onClick,
    comeForZone
}) => {

    const isInDragSelection = draggedCells.findIndex((e) => e === index) > -1;

    const coordinate  = index.split(",");
    let obj           = gridCells.filter( (e) => e.x === coordinate[0] && e.y === coordinate[1])[0];

    if (obj === undefined)
        obj = {}

    const borderColor = obj.borderColor !== undefined && obj.borderColor !== '' ? obj.borderColor : '#454545'

    return (
        <Button
            style={{
                border: "1px solid #454545",
                borderColor: borderColor,
                backgroundColor: isInDragSelection ? "blue" : color === '' ? 'transparent' : color,
                color: 'black',
                width: comeForZone ? 30 : 20,
                height:  comeForZone ? 30 : 20,
            }}
            key={ coordinate[1] }
            variant="flat"
            onClick={ onClick }
        >
            { children }
        </Button>
    );
};

export default OverviewSeat;
