// Redux logs module
// see https://github.com/erikras/ducks-modular-redux

import { Log } from '../../models';
import { Logs } from '../../services';

const LOADING_LOGS         = 'kronos/logs/LOADING_LOGS';
const LOADING_LOGS_SUCCESS = 'kronos/logs/LOADING_LOGS_SUCCESS';
const LOADING_LOGS_FAILURE = 'kronos/logs/LOADING_LOGS_FAILURE';

const CLEAR_LAST_SCREENING_IMPORT_LOGS          = 'kronos/logs/CLEAR_LAST_SCREENING_IMPORT_LOGS';
const LOADING_LAST_SCREENING_IMPORT_LOG_SUCCESS = 'kronos/logs/LOADING_LAST_SCREENING_IMPORT_LOG_SUCCESS';
const TKTSYNCING_LEGACY_SCREENINGS_SUCCESS      = 'kronos/legacyScreenings/TKTSYNCING_LEGACY_SCREENINGS_SUCCESS';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    logSuccessfullyCreated: false,
    logs: [],
    lastScreeningImportLogs: []
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_LOGS:
            return {
                ...state,
                isLoading: true,
                logSuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case CLEAR_LAST_SCREENING_IMPORT_LOGS:
            return {
                ...state,
                lastScreeningImportLogs: []
            };
        case LOADING_LOGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                logs: action.logs
            };
        case TKTSYNCING_LEGACY_SCREENINGS_SUCCESS:
            console.log({ action });
            return {
                ...state,
                lastScreeningImportLogs: action.imported?.logs.map(log => new Log(log))
            };
        case LOADING_LOGS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingLogs() { return { type: LOADING_LOGS } }
function loadingLogsSuccess(logs) { return { type: LOADING_LOGS_SUCCESS, logs } }
function loadingLogsFailure(error) { return { type: LOADING_LOGS_FAILURE, error } }
export function loadLogs(params) {
    return (dispatch) => {
        dispatch(loadingLogs());
        Logs.list(params)
            .then(data => {
                const logs = data.logs;
                dispatch(loadingLogsSuccess(logs));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingLogsFailure(err))
            });
    }
}
export function loadScreeningLogs(screeningId) {
    return loadLogs({ screening_ids: screeningId });
};

function loadingLastScreeningLogSuccess(logs) {
    return {
        logs,
        type: LOADING_LAST_SCREENING_IMPORT_LOG_SUCCESS
    }
}
export function clearLastScreeningImportLogs() {
    return { type: CLEAR_LAST_SCREENING_IMPORT_LOGS }
}
export function loadLastScreeningImportLogs(_ids) {
    return (dispatch) => {
        dispatch(loadingLogs());
        Logs.list({ _ids }).then(data => {
            const logs = data.logs;
            dispatch(loadingLastScreeningLogSuccess(logs));
        })
        .catch(err => {
            console.log("err => ", err);
            dispatch(loadingLogsFailure(err))
        });
    }
};
