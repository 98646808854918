import React, { useState, useEffect} from 'react';
import { Field } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { TabContent, TabPane, Nav, NavItem, NavLink, FormGroup, Label, Row, Col, Input, InputGroupAddon, Button, FormFeedback } from 'reactstrap';
import { RolesField, TickettypesField, MultiSelectField } from '../../components';
import classnames from 'classnames';
import HelpTooltips from '../HelpTooltips/HelpTooltips';
import AdditionnalClassesField from '../AdditionnalClassesField';
import { changeBucket } from '../../redux';
import './BucketForm.css';

const BucketForm = ({ bucket, bucketIndex, isLoading, bucketIds, smallCard }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState('base');
    const [id, setId]               = useState(bucket?._id);

    const { tickettypes } = useSelector(state => state.tickettypes);
    const { user }        = useSelector(state => state.auth);
    const { setting }     = useSelector(state => state.settings);
    const { refs }        = useSelector(state => state.tickettypes);

    const toggle = tab => {
        if (activeTab !== tab)
            setActiveTab(tab);
    }

    const mayStealFromOptions = React.useMemo(() => (bucketIds || [])
        .filter(_id => _id !== bucket?._id)
        .map(_id => {
            return { key: _id, text: _id, value: _id };
        }),
    [bucket?._id, bucketIds]);

    const addOnlyForRolesRule = () => {
        dispatch(changeBucket({
            ...bucket,
            rules: {
                ...bucket.rules,
                only_for_roles: []
            }
        }));
    };

    const removeOnlyForRolesRule = () => {
        const { only_for_roles, ...rest } = bucket?.rules;
        dispatch(changeBucket({
            ...bucket,
            rules: rest
        }));
    };

    const addOnlyForTickettypesRule = () => {
        dispatch(changeBucket({
            ...bucket,
            rules: {
                ...bucket.rules,
                only_for_tickettypes: []
            }
        }));
    };

    const removeOnlyForTickettypesRule = () => {
        const { only_for_tickettypes, ...rest } = bucket?.rules;
        dispatch(changeBucket({
            ...bucket,
            rules: rest
        }));
    };

    return (
        <div className='BucketForm'>
            <FormGroup row >
                <Label for="_id" className="text-v-center" sm={smallCard ? 2 : 12}>{t("tickettypes._id")}</Label>
                <Col sm={smallCard ? 10 : 12}>
                    <Field component="input" type="text" className="form-control" name={`buckets[${bucketIndex}]._id`} />
                </Col>
            </FormGroup>
            <hr />
            <FormGroup row>
                <Label for="total_capacity" sm={12}>{t("bucketlists.bucket.total_capacity")}</Label>
                <Col sm={12} data-tip data-for={`totalCapacityTip.${bucketIndex}`}>
                    <Field component="input" type="text" className="form-control" name={`buckets[${bucketIndex}].total_capacity`} />
                    <HelpTooltips id={`totalCapacityTip.${bucketIndex}`} value='bucket.total_capacity' />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="may_steal_from" sm={12}>{t("bucketlists.bucket.may_steal_from")}</Label>
                <Col sm={12} data-tip data-for={`mayStealFromTip.${bucketIndex}`}>
                    <MultiSelectField name={`buckets[${bucketIndex}].may_steal_from`} options={mayStealFromOptions} />
                    <HelpTooltips id={`mayStealFromTip.${bucketIndex}`} value='bucket.may_steal_from' />
                </Col>
            </FormGroup>
            <Row>
                <Col>
                    <FormGroup row>
                        <Label for="name" sm={12}>{t("bucketlists.bucket.not_before")}</Label>
                        <Col sm={12} data-tip data-for={`notBeforeTip.${bucketIndex}`}>
                            <Field component="input" type="text" className="form-control" name={`buckets[${bucketIndex}].rules.not_before`} />
                            <HelpTooltips id={`notBeforeTip.${bucketIndex}`} value='bucket.not_before' />
                        </Col>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup row>
                        <Label for="name" sm={12}>{t("bucketlists.bucket.not_after")}</Label>
                        <Col sm={12} data-tip data-for={`notAfterTip.${bucketIndex}`}>
                            <Field component="input" type="text" className="form-control" name={`buckets[${bucketIndex}].rules.not_after`} />
                            <HelpTooltips id={`notAfterTip.${bucketIndex}`} value='bucket.not_after' />
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup row>
                <Label for="name" sm={12}>{t("bucketlists.bucket.only_for_roles")}</Label>
                <Col sm={12} data-tip data-for={`roleTip.${bucketIndex}`}>
                    {('only_for_roles' in bucket.rules) ? (
                        <div className="d-flex justify-between">
                            <div className="w-100">
                                <RolesField
                                    useUserRoles
                                    placeholder={t('bucketlists.bucket.no_role_desc')}
                                    name={`buckets[${bucketIndex}].rules.only_for_roles`}
                                />
                            </div>
                            <div className="w-100 d-flex justify-end">
                                <Button color="primary" onClick={ removeOnlyForRolesRule }>
                                    <i className="fa fa-trash" />&nbsp;
                                    { t('bucketlists.bucket.remove_only_for_roles_rule') }
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="d-flex justify-between">
                            <b className="font-italic">
                                { t('bucketlists.bucket.all_roles_desc') }
                            </b>
                            <Button color="primary" onClick={ addOnlyForRolesRule }>
                                <i className="fa fa-plus" />&nbsp;
                                { t('bucketlists.bucket.add_only_for_roles_rule') }
                            </Button>
                        </div>
                    )}
                </Col>
                <HelpTooltips id={`roleTip.${bucketIndex}`} value='bucket.only_for_roles' />
            </FormGroup>
            {tickettypes && (
                <FormGroup row>
                    <Label for="name" sm={12}>{t("bucketlists.bucket.only_for_tickettypes")}</Label>
                    <Col sm={12} data-tip data-for={`pricelimiteTip.${bucketIndex}`}>
                        {('only_for_tickettypes' in bucket.rules) ? (
                            <div className="d-flex justify-between">
                                <div className="w-100">
                                    <TickettypesField
                                        placeholder={ t('bucketlists.bucket.no_tickettype_desc') }
                                        name={`buckets[${bucketIndex}].rules.only_for_tickettypes`}
                                        tickettypes={tickettypes}
                                    />
                                </div>
                                <div className="w-100 d-flex justify-end">
                                    <Button color="primary" onClick={ removeOnlyForTickettypesRule }>
                                        <i className="fa fa-trash" />&nbsp;
                                        { t('bucketlists.bucket.remove_only_for_tickettypes_rule') }
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex justify-between">
                                <b className="font-italic">
                                    { t('bucketlists.bucket.all_tickettypes_desc') }
                                </b>
                                <Button color="primary" onClick={ addOnlyForTickettypesRule }>
                                    <i className="fa fa-plus" />&nbsp;
                                    { t('bucketlists.bucket.add_only_for_tickettypes_rule') }
                                </Button>
                            </div>
                        )}
                    </Col>
                    <HelpTooltips id={`pricelimiteTip.${bucketIndex}`} value='bucket.only_for_tickettypes' />
                </FormGroup>
            )}
        </div>
    );
}

export default BucketForm;

