import React, { forwardRef, useState, useEffect } from "react";
import { Rnd } from 'react-rnd';
import "./DragAndResize.css";
import { Screen } from '../../../../../services';

const DragAndResize = forwardRef((props, ref) => {

    const { widget, fonts, inEdition, active, onClick, onDelete, onUndo, onRedo } = props;

    const style = {
        position: 'absolute',
        top: Screen.cmToPx(inEdition ? 0 : widget.position?.y),
        left: Screen.cmToPx(inEdition ? 0 : widget.position?.x),
        width: Screen.cmToPx(widget.dimensions?.width),
        height: Screen.cmToPx(widget.dimensions?.height),
        transform: `rotate(${widget.transform.rotation || 0}deg)`,
    };

    const onResizeStop = (e, direction, ref, delta, position) => {
        widget.setWidth(widget.dimensions.width + Screen.pxToCm(delta.width));
        widget.setHeight(widget.dimensions.height + Screen.pxToCm(delta.height));
        if (direction === 'left')
            widget.setX(Screen.pxToCm(position.x));
        if (direction === 'top')
            widget.setY(Screen.pxToCm(position.y));
        props.onChange(widget);
    };

    const onDragStop = (e, d) => {
        widget.setX(Screen.pxToCm(d.x));
        widget.setY(Screen.pxToCm(d.y));
        props.onChange(widget);
    };

    const onKeyDown = e => {
        e.preventDefault();
        e.stopPropagation();

        // delete the current widget
        if (e.key === 'Delete')
            return onDelete();

        if (e.ctrlKey) {
            switch (e.key) {
                case 'z':
                    return onUndo();
                case 'y':
                    return onRedo();
            }
        }

        // move or resize the current widget
        const ratioImg = widget.dimensions.width / widget.dimensions.height;
        const distance = e.shiftKey ? 1 : 0.1;
        if (e.altKey) {
            // resize the current widget
            switch (e.key) {
                case 'ArrowUp':
                    widget.setHeight(widget.dimensions.height - distance);
                    if(widget.options?.lockRatio) {
                        widget.setWidth(widget.dimensions.width - distance/ratioImg);
                    }
                    props.onChange(widget);
                    break;
                case 'ArrowDown':
                    widget.setHeight(widget.dimensions.height + distance);
                    if(widget.options?.lockRatio) {
                        widget.setWidth(widget.dimensions.width + distance*ratioImg);
                    }
                    props.onChange(widget);
                    break;
                case 'ArrowLeft':
                    widget.setWidth(widget.dimensions.width - distance);
                    if(widget.options?.lockRatio) {
                        widget.setHeight(widget.dimensions.height - distance/ratioImg);
                    }
                    props.onChange(widget);
                    break;
                case 'ArrowRight':
                    widget.setWidth(widget.dimensions.width + distance);
                    if(widget.options?.lockRatio) {
                        widget.setHeight(widget.dimensions.height + distance*ratioImg);
                    }
                    props.onChange(widget);
                    break;
            }
        } else {
            // move the current widget
            switch (e.key) {
                case 'ArrowUp':
                    widget.setY(widget.position.y - distance);
                    props.onChange(widget);
                    break;
                case 'ArrowDown':
                    widget.setY(widget.position.y + distance);
                    props.onChange(widget);
                    break;
                case 'ArrowLeft':
                    widget.setX(widget.position.x - distance);
                    props.onChange(widget);
                    break;
                case 'ArrowRight':
                    widget.setX(widget.position.x + distance);
                    props.onChange(widget);
                    break;
            }
        }
    };

    const editableContent = (content) => {
        const enableResizing = {
            bottom: widget.isResizable(),
            bottomLeft: widget.isResizable(),
            bottomRight: widget.isResizable(),
            left: widget.isResizable() || widget.isResizableHorizontallyOnly(),
            right: widget.isResizable() || widget.isResizableHorizontallyOnly(),
            top: widget.isResizable(),
            topLeft: widget.isResizable(),
            topRight: widget.isResizable()
        };

        return (
            <Rnd
                className="rnd"
                enableResizing={ active ? enableResizing : false }
                onResizeStop={ onResizeStop }
                onDragStop={ onDragStop }
                position={{
                    x: Screen.cmToPx(widget.position.x),
                    y: Screen.cmToPx(widget.position.y),
                    width: Screen.cmToPx(widget.dimensions.width),
                    height: Screen.cmToPx(widget.dimensions.heigth),
                }}
                lockAspectRatio={widget.options?.lockRatio}
                size={{
                    width: Screen.cmToPx(widget.dimensions.width),
                    height: Screen.cmToPx(widget.dimensions.height)
                }}
                dragGrid={[Screen.cmToPx(0.1), Screen.cmToPx(0.1)]}
                bounds={ Screen.scale === 1 ? '.previewWrapper' : null }
            >
                { content }
            </Rnd>
        );
    };

    const widgetContent = (
        <div style={ style }>
            { widget?.getPreview({
                inEdition: props.inEdition,
                lang: props.lang,
                setting: props.setting,
                fonts,
                ref,
                programmation: props.programmation,
                place: props.place,
                tickettype: props.tickettype,
                pricing: props.pricing
            })}
        </div>
    );

    return (
        <div onClick={ onClick } tabIndex={ -1 } onKeyDown={ active ? onKeyDown : null } className={ `DragAndResize ${active ? 'active' : ''}` }>
            { props.inEdition ? editableContent(widgetContent) : widgetContent }
        </div>
    );
});

export default DragAndResize;
