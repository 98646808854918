import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button, ButtonGroup, Card, CardBody, CardTitle, CardFooter } from 'reactstrap';
import { Field, submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import FileUpload from '../../../../components/FileUpload/FileUpload';
import { Utils } from '../../../../services';
import _ from 'lodash';

import './ProgrammationTasks.css';

const ProgrammationTasks = props => {
    const { t } = useTranslation();

    const [ currentTaskActivityId, setCurrentTaskActivitiyId ] = useState(null);
    const [ currentTaskStatus, setCurrentTaskStatus ]          = useState(null);
    const [ currentTaskUserId, setCurrentTaskUserId ]          = useState(null);

    const onChangeStatus = e => {
        setCurrentTaskStatus(e.target.value);
    }

    const onChangeActivity = e => {
        setCurrentTaskActivitiyId(e.target.value);
    }

    const onChangeUser = e => {
        setCurrentTaskUserId(e.target.value);
    }

    const dispatch = useDispatch();
    const add = e => {
        const { meta: { form }, dispatchSubmitForm } = props;
        dispatch(submit(form));

        setCurrentTaskStatus(null);
        setCurrentTaskActivitiyId(null);
        setCurrentTaskUserId(null);
    }

    const remove = i => {
        const { fields, tasks, meta: { form }, dispatchSubmitForm } = props;

        fields.remove(i);
        tasks.splice(i, 1);
        dispatch(submit(form));
    }

    const { fields, tasks, activities, users, onDelete } = props;
    const default_lang = Utils.default_lang();

    const isLoading = false;
    const filtered_users = currentTaskActivityId ?
        _.filter(users, (u) => _.filter(u.activities, (a) => a.id == currentTaskActivityId).length) :
        users;

    const newName = field => `${props.fields.name.replace('tasks', 'new_task')}.${field}`;
    return (
        <div className="ProgrammationTasks">
            <Row>
                <Col>
                    <CardBody>
                        <Row>
                            <Col sm={3}>
                                <Field onChange={onChangeActivity} component="select" className="form-control" type="text" name={newName('activity_id')}>
                                    <option key="0" value="">---</option>
                                    {
                                        activities.map((a) => <option key={a.id} value={a.id}>{a.name[default_lang]}</option>)
                                    }
                                </Field>
                            </Col>
                            {currentTaskActivityId &&
                            <Fragment>
                                <Col sm={3}>
                                    <Field onChange={onChangeStatus} component="select" className="form-control" type="text" name={newName('status')}>
                                        <option value="">---</option>
                                        <option value={Utils.TASK_STATUS_NEW}>{t('tasks.status_new')}</option>
                                        <option value={Utils.TASK_STATUS_FREE}>{t('tasks.status_free')}</option>
                                        <option value={Utils.TASK_STATUS_ASSIGNED}>{t('tasks.status_assigned')}</option>
                                    </Field>
                                </Col>
                                {currentTaskStatus === Utils.TASK_STATUS_ASSIGNED &&
                                <Col sm={3}>
                                    <Field  onChange={onChangeUser} component="select" className="form-control" type="text" name={newName('user_id')}>
                                        <option key="" value="">---</option>
                                        {
                                            filtered_users.map((u) => <option key={u.id} value={u.id}>{u.firstname} {u.lastname}</option>)
                                        }
                                    </Field>
                                </Col>
                                }
                                {currentTaskStatus && (
                                    currentTaskStatus != Utils.TASK_STATUS_ASSIGNED || currentTaskUserId) &&
                                <Col sm={3}>
                                    <Button type="button" onClick={add} color="info">
                                        {isLoading ?
                                            <span> &nbsp;<i className="fa fa-spinner fa-spin"></i> </span>
                                            :
                                            <span> <i className="fa fa-plus"></i>&nbsp; {t("common.add")} </span>
                                        }
                                    </Button>
                                </Col>
                                }
                            </Fragment>
                            }
                        </Row>
                    </CardBody>
                </Col>
            </Row>
            <Row>
                {tasks.map((task, i) =>
                    <Col key={i} className="task-wrapper" xs="12" sm="12" md="4">
                        <Card className="task">
                            <CardTitle>
                                <div className="text-center">
                                    <h5>{ task.activity.name[default_lang] }</h5>
                                </div>
                            </CardTitle>
                            <CardBody>
                                <div className="text-center">
                                    {task.user &&
                                        <h4>{ task.user.firstname } { task.user.lastname }</h4>
                                    }
                                </div>
                            </CardBody>
                            <CardFooter className="text-center">
                                <ButtonGroup>
                                    <Button color="danger" size="sm" onClick={ () => { remove(i);  }}>
                                        <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                    </Button>
                                </ButtonGroup>
                                <Field component="input" type="hidden" className="form-control" name={`task[${i}].activity`} />
                                <Field component="input" type="hidden" className="form-control" name={`task[${i}].firstname`} />
                                <Field component="input" type="hidden" className="form-control" name={`task[${i}].lastname`} />
                            </CardFooter>
                        </Card>
                    </Col>
                )}
            </Row>
        </div>
    );
}

export default ProgrammationTasks;
