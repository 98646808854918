import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PageTitle, TableButtons, CreateButton, Table } from '../../../components';
import { Filters } from '../../../services';
import { Utils } from '../../../services';
import 'react-table-6/react-table.css'
import { loadCashregisters, loadSalepoints } from '../../../redux';

const CashregistersList = props => {
    const { t }        = useTranslation();
    const dispatch     = useDispatch();
    const default_lang = Utils.default_lang();

    useEffect(() => {
        dispatch(loadSalepoints());
        dispatch(loadCashregisters());
    }, []);

    const { salepoints }    = useSelector(state => state.salepoints);
    const { cashregisters } = useSelector(state => state.cashregisters);

    if (!cashregisters)
        return null;

    cashregisters.forEach(cashregister => {
        const salepoint = salepoints?.find(salepoint => salepoint._id === cashregister.salepoint_id);
        cashregister.salepoint = salepoint;
    });

    const columns = [{
        Header: t("cashregisters.name"),
        accessor: "name",
        filterMethod: Filters.textFilterMethod(`name.${default_lang}`),
        Filter: Filters.textFilter,
        filterAll: true,
        Cell: row => row.value && row.original.name[default_lang]
    }, {
        Header: t("cashregisters.salepoint"),
        accessor: "salepoint",
        filterMethod: Filters.textFilterMethod("salepoint._id"),
        Filter: Filters.posFilter(salepoints),
        filterAll: true,
        Cell: row => row.original.salepoint && row.original.salepoint.name[default_lang]
    }, {
        Header: t("cashregisters.auto_closing"),
        accessor: "auto_closing",
        filterMethod: Filters.textFilterMethod("auto_closing"),
        Filter: Filters.cashregisterClosingStatusFilter(),
        filterAll: true,
        Cell: row => row.original.auto_closing ? t('cashregisters.auto_closing_enabled') : t('cashregisters.auto_closing_disabled')
    }, {
        Header: t("common.actions"),
        accessor: "_id",
        maxWidth: 110,
        filterable: false,
        Cell: row => (
            <TableButtons
                id={row.value}
                actions={["edit", "delete", "show"]}
                module="cashregisters"
            />
        )
    }];

    const actions =
        <CreateButton
            module="cashregisters"
            text="cashregisters.add"
        />

    const title =
    <span>{t("cashregisters.cashregister_plural")}</span>

    return (
        <div className="CashregistersList">
            <PageTitle icon="th" title={ title } actions={ actions } />
            <Table id="cashregistersTable" data={ cashregisters } columns={ columns } />
        </div>
    );
}

export default CashregistersList;
