import RestClient from './RestClient';
import { Programmation } from '../../models';
import _ from 'lodash';

class Programmations extends RestClient {
    constructor() {
        super({
            "model": Programmation,
            "entryPoint": "programmation",
            "resource": "programmation",
            "resources": "programmations"
        });

        this.GENERATE_SCREENINGS_PATH = '/generate_screenings';
    }

    list(filters) {
        filters = _.pickBy(filters || {}, _.identity);

        return this.GET('/programmation/list', filters)
            .then(response => response.json())
            .then(json => {
                if (json.programmations)
                    return json;
                throw new Error(json.flash.error);
            });
    }

    generateScreenings(programmation_id) {
        return this.POST(`/${this.entryPoint}${this.GENERATE_SCREENINGS_PATH}/${programmation_id}`, {})
            .then(response => response.json())
            .then(json => {
                if (json.programmation)
                    return json;
                throw new Error(json.flash.error);
            });
    }
}

const ProgrammationsApi = new Programmations();

export default ProgrammationsApi;
