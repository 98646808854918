import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { updateUi, tktsyncLegacyScreenings } from '../../redux';

import './TktSyncButton.css';

const TktSyncButton = ({collapsed}) => {
    const { t }                                = useTranslation();
    const dispatch                             = useDispatch();
    const { setting }                          = useSelector(state => state.settings);
    const { showScreeningsImport }             = useSelector(state => state.ui);
    const { isTktsyncing, hasTktsyncingError } = useSelector(state => state.legacyScreenings);

    const config = React.useMemo(() => {
        if (!setting?._id)
            return null;

        return setting?.getScreeningsImportConfiguration();
    }, [setting]);

    if (!config)
        return null;

    const togglePanel = () => {
        dispatch(updateUi({ showScreeningsImport: !showScreeningsImport }))
    }

    const color = hasTktsyncingError ? 'warning' : 'info';
    return (
        <Button onClick={ togglePanel } color={ color } className="TktSyncButton d-flex">
            <i className={`fa ${isTktsyncing ? 'fa-spinner fa-spin' : 'fa-download'}`}></i>
            {!collapsed && <span className="truncate">&nbsp;{ t(`events.tktsync_${config.source}`) }</span>}
        </Button>
    );
}

export default TktSyncButton;
