import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import ProgrammationGroupForm from '../ProgrammationGroupForm/ProgrammationGroupForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { loadProgrammationGroup, updateProgrammationGroup } from '../../../redux';

const ProgrammationGroupEdit = ({ match }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const groupId = match.params.groupId;
        dispatch(loadProgrammationGroup(groupId));
    }, []);

    const submit = values => {
        dispatch(updateProgrammationGroup(values));
    }

    const goBack = () => {
        window.history.back();
    }

    const { programmation_group } = useSelector(state => state.programmation_groups);

    let title = <span>{t('programmations.programmation')}</span>;
    if (programmation_group)
        title = (
            <span>
                {t('programmations.programmation')}
                <small> |&nbsp;{ t('programmations.update_programmation', {})} </small>
            </span>
        );

    const actions =
    <div>
        {programmation_group &&
        <ButtonGroup>
            <Button onClick={ goBack } color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
            </Button>
        </ButtonGroup>
        }
    </div>

    return (
        <div className="ProgrammationGroupEdit">
            <PageTitle icon="th" title={ title } actions={ actions } />

            {programmation_group &&
                <ProgrammationGroupForm onSubmit={ submit } />
            }
        </div>
    );
}

export default ProgrammationGroupEdit;
