import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import PlaceForm from '../PlaceForm/PlaceForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { loadPlace, updatePlace } from '../../../redux/modules/places';

class PlaceEdit extends Component<{}> {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        const placeId = this.props.match.params.placeId;
        // Load places on component mount
        this.props.dispatchLoadPlace(placeId);
    }

    submit(values) {
        this.props.dispatchUpdatePlace(values);
    }

    render() {
        const { place } = this.props.places;
        const { t } = this.props;

        let title = <span>{t('places.place')}</span>;
        if (place)
            title = (
                <span>
                    {t('places.place')}
                    <small> |&nbsp;{ t('places.update_place', { name: place.name })} </small>
                </span>
            );

        const actions =
        <div>
            {place &&
            <ButtonGroup>
                <Button tag={Link} to="/places" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            </ButtonGroup>
            }
        </div>

        return (
            <div className="PlaceEdit">
                <PageTitle icon="th" title={ title } actions={ actions } />

                {place &&
                    <PlaceForm placeId={place._id} onSubmit={this.submit} />
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadPlace: (placeId) => loadPlace(placeId),
    dispatchUpdatePlace: (place) => updatePlace(place)
}

const mapStateToProps = state => ({
    places: state.places,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(PlaceEdit))
