import BaseModel from './BaseModel';
import moment from 'moment';
import {  Utils } from '../services';

/** Class representing a programmation. */
class Programmation extends BaseModel {
    describeDays(t, lang) {
        if (!this.days?.length)
            return null;

        if (this.days.length === 7)
            return t('programmations.all_days');

        return t('programmations.each_days', {
            days: Utils.joinWithComma(
                this.days.map(d => moment().locale(lang).day(d).format('dddd')),
                t
            )
        });
    }

    describeTimes(t, lang) {
        if (!this.times?.length)
            return null;

        return t('programmations.at', {
            time: Utils.joinWithComma(
                this.times.map(time => moment()
                    .locale(lang)
                    .hour(time.split(':')[0])
                    .minutes(time.split(':')[1])
                    .format('LT')
                ),
                t
            )
        });
    }

    describeTasks(t, lang) {
        if (!this.tasks?.length)
            return null;

        const activities = {};
        this.tasks.forEach(task => {
            if (!task.activity)
                return;

            const name = Utils.localized_or_fallback(task.activity.name, lang);
            activities[name] = (activities[name] || 0) + 1;
        });

        return Utils.joinWithComma(Object.keys(activities).map(name => `${activities[name]} x ${name}`), t);
    }
}

export default Programmation;
