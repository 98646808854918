import React, { useState }  from "react";
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form'
import { Row, Col, Label } from 'reactstrap';
import { PdfLayout } from '../../../../../models';
import { TranslatableField } from '../../../../../components';

const PdfLayoutOptions = () => {
    const { t } = useTranslation();

    const [layoutType, setLayoutType] = useState(PdfLayout.LAYOUT_TYPE_TICKET);

    return (
        <div className="PdfLayoutOptions">
            <Row>
                <Col>
                    <Label>{t('layouts.layout.name')}</Label>
                    <TranslatableField  data-for='layouts.name' required component="input" className="form-control" type="text" name="name" placeholder={t("tickettypes.name_placeholder")} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>{t('layouts.layout.type')}</Label>
                    <Field
                        component="select"
                        className="form-control w-100"
                        name="type"
                        value={ layoutType }
                        onChange= { e => setLayoutType(e.target.value)}
                        style={{width : '150px', height: '34px', marginRight: '10px'}}
                    >
                        { PdfLayout.types().map(type => {
                              return (
                                <option key={ type } value={ t(`layouts.type_${type}`).toLowerCase() }>
                                  { t(`layouts.type_${type}`) }
                                </option>
                              )
                        })}
                    </Field>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>{t('layouts.layout.target')}</Label>
                    <Field
                        component="select"
                        className="form-control w-100"
                        name="target"
                        value={ layoutType }
                        onChange= { e => setLayoutType(e.target.value)}
                        style={{width : '150px', height: '34px', marginRight: '10px'}}
                    >
                        { PdfLayout.targets().map(target => {
                              return (
                                <option key={ target } value={ target }>
                                  { t(`layouts.target_${target}`) }
                                </option>
                              )
                        })}
                    </Field>
                </Col>
            </Row>
        </div>
    );
}

export default PdfLayoutOptions;
