import RestClient from './RestClient';
import { ProgrammationGroup } from '../../models';
import _ from 'lodash';

class ProgrammationGroups extends RestClient {
    constructor() {
        super({
            "model": ProgrammationGroup,
            "entryPoint": "programmation_group",
            "resource": "programmation_group",
            "resources": "programmation_groups"
        });
    }
}

const ProgrammationGroupsApi = new ProgrammationGroups();

export default ProgrammationGroupsApi;
