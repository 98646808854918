import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import PageTitle from '../../../components/PageTitle/PageTitle';
import TableStats from '../../../components/TableStats/TableStats';
import { BarChart } from '../../../components/Chart/';
import StatsHeader from '../StatsHeader/StatsHeader'
import { Utils, Filters } from '../../../services';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import "./StatsRooms.css";


const StatsRooms = ({ filters }) => {
    const { t } = useTranslation();
    const title = t("statstypes.texts.halls")
    const { stats } = useSelector(state => state.stats);

    const data = stats.places;

    if (!data || !data.films)
        return null;

    const tempArray = data.films;

    //Columns for stats per halls table 
    const columns = [
        {
            Header: t("statstypes.form.hall"),
            accessor: "hall",
            filterMethod: Filters.textFilterMethod(`hall`),
            Filter: Filters.textFilter,
            filterAll: true,
            Footer: row => (<b>{t("statstypes.total")}</b>),
            className: "sticky",
            headerClassName: "sticky"
        },
        {
            Header: t("statstypes.screenings"),
            accessor: "screenings",
            filterable: false,
            maxWidth: 110,
            className: 'align-center-stats',
            Footer: row => (<b>{data?.total?.nb_screenings}</b>)
        },
        {
            Header: t("statstypes.chart.paid_entries"),
            accessor: "pay",
            filterable: false,
            maxWidth: 110,
            className: 'align-center-stats',
            Footer: row => (<b>{data?.total?.stats?.nbPaid}</b>)
        },
        {
            Header: t("statstypes.chart.free_entries"),
            accessor: "free",
            filterable: false,
            maxWidth: 110,
            className: 'align-center-stats',
            Footer: row => (<b>{data?.total?.stats?.nbFree}</b>)
        },
        {
            Header: t("statstypes.chart.pass_entries"),
            accessor: "pass",
            filterable: false,
            maxWidth: 110,
            className: 'align-center-stats',
            Footer: row => (<b>{data?.total?.stats?.nbPass}</b>)
        },
        {
            Header: t("statstypes.entries"),
            accessor: "entries",
            filterable: false,
            maxWidth: 110,
            className: 'align-center-stats',
            Footer: row => (<b>{data?.total?.stats?.nbTotal}</b>)
        },
        {
            Header: t("statstypes.chart.income"),
            accessor: "revenue",
            filterable: false,
            Cell: row => (
                row.value &&
                row?.value?.toFixed(2) + " " + Utils.currency()
            ),
            maxWidth: 110,
            className: 'align-center-stats',
            Footer: row => (<b>{data?.total?.stats?.total?.toFixed(2) + ' ' + Utils.currency()}</b>)
        }
    ];


    //Gets table data. Is refreshed if data prop is modified
    const dataTable = 
        Object.keys(tempArray).map((hall) => (
            {
                hall: hall,
                screenings: tempArray[hall].screenings.length,
                free: tempArray[hall].stats.nbFree,
                pass: tempArray[hall].stats.nbPass,
                pay: tempArray[hall].stats.nbPaid,
                entries: tempArray[hall].stats.nbTotal,
                revenue: tempArray[hall].stats.total
            }));

    const dataGraphOne =
        _.compact(Object.keys(tempArray).map(hall => {
            //check if there are entries
            if (tempArray[hall].stats.nbFree + tempArray[hall].stats.nbPass + tempArray[hall].stats.nbPaid > 0) {
                return {
                    id: hall,
                    [t("statstypes.chart.free_entries")]: tempArray[hall].stats.nbFree,
                    [t("statstypes.chart.pass_entries")]: tempArray[hall].stats.nbPass,
                    [t("statstypes.chart.paid_entries")]: tempArray[hall].stats.nbPaid
                }
            }
        }));
    const dataGraphTwo =
        _.compact(Object.keys(tempArray)
            .filter(hall => tempArray[hall].stats.total > 0)
            .map(hall => {
                return {
                    id: hall,
                    [t("statstypes.chart.income")]: tempArray[hall].stats.total
                }
        }));


    return (
        <div className="StatsRooms">
            <div className='noPrint'>
                <PageTitle icon="map-marker" title={title} />
            </div>
            <StatsHeader
                filters={filters}
                title={<PageTitle
                    icon="map-marker"
                    title={title}
                />}
            />

            {dataTable && <TableStats id="roomsStatsTable" data={dataTable} columns={columns} print></TableStats>}
            <Row className='noPrint'>
                {/* If stats for graphs One are set */}
                {dataGraphOne.length > 0 &&
                    <Col sm={12} lg={6} style={{ height: "400px" }}>
                        <BarChart
                            data={dataGraphOne}
                            keys={[t("statstypes.chart.free_entries"), t("statstypes.chart.pass_entries"), t("statstypes.chart.paid_entries")]}
                            axisLeftLegend=""
                            axisBottomLegend=""
                            axisBottomTickRotation={35}
                            tooltipFormat="$"
                            enableLabel={false}
                        />
                    </Col>}


                {/* If stats for graphs are set */}
                {dataGraphTwo.length > 0 &&
                    <Col sm={12} lg={6} style={{ height: "400px" }}>
                        <BarChart
                            data={dataGraphTwo}
                            keys={[t("statstypes.chart.income")]}
                            axisLeftLegend=""
                            axisBottomLegend=""
                            axisBottomTickRotation={35}
                            tooltipFormat="$"
                            enableLabel={false}
                        />
                    </Col>}
            </Row>
        </div>
    )
}

export default StatsRooms;
