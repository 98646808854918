import React, { useState } from 'react';
import { Row, Col, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import _ from 'lodash';

import './HoursField.css';

const HoursField = ({ input }) => {
    const [newTime, setNewTime] = useState('');

    const handleChange = (e) => {
        const value = e.target.value;
        
        // Only allow digits and colon, up to 5 characters
        if (!/^[\d:]*$/.test(value) || value.length > 5) {
            return;
        }

        let newString = value;

        // Auto-add colon after 2 digits if not present
        if (newString.length === 2 && newString.search(/:/) === -1) {
            newString += ":";
        }

        setNewTime(newString);
    };

    // Keep the keydown handler for Enter key and desktop experience
    const handle_key_down = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            add();
            return;
        }
    }

    const add = () => {
        // check time format
        const regex    = /([0-1][0-9]?|[2][0-3])[:][0-5][0-9]/;
        let   tempTime = newTime;
        const values   = input.value;

        if (/^\d:\d{2}$/.test(tempTime))
            tempTime = "0" + tempTime;

        switch (tempTime.length) {
            case 1:
                tempTime = "0" + tempTime + ":00";
                break;
            case 2:
                tempTime += ":00"
                break;
            case 3:
                tempTime += "00"
                break;
            case 4:
                tempTime += "0"
                break;
        }
        const matches = tempTime.match(regex);
        if (matches) {
            values.push(tempTime);
            input.onChange(values.map(v => v));
        }
        setNewTime("");
    }

    const remove = (i) => {
        const temp = _.cloneDeep(input.value);
        temp.splice(i, 1);
        input.onChange(temp);
        setNewTime("");
    }

    return (
        <div className="HoursField">
            <Row>
                <Col>
                    <InputGroup>
                        <Input 
                            onChange={handleChange}
                            onKeyDown={handle_key_down} 
                            value={newTime} 
                            placeholder="17:30"
                        />
                        <InputGroupAddon addonType="append">
                            <Button type="button" color="info" size="sm" onClick={add}>
                                <span> <i className="fa fa-plus"></i>&nbsp;</span>
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col className="times">
                    {input?.value && input.value.map((t, i) =>
                        <a key={i} onClick={(e) => {remove(i)}}>
                            <span className="icon"><i className="fa fa-trash"></i>&nbsp;</span>
                            <span className="time">{t}</span>
                        </a>
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default HoursField;
