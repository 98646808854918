import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Col, Row, Collapse, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import {
    updateUi,
    loadScreenings,
    tktsyncLegacyScreenings,
    clearLastScreeningImportLogs,
    loadLastScreeningImportLogs
} from '../../redux';
import moment from 'moment';
import { Log, Setting } from '../../models';
import { Utils } from '../../services';
import i18n from '../../locales/i18n';
import _ from 'lodash';
import classnames from 'classnames';

import './ScreeningsImportPanel.css';
import ModalComponent from '../ModalComponent';

const ScreeningsImportPanel = () => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState('screenings');

    const {
        isTktsyncing,
        hasTktsyncingError,
        tktsyncingErrorMessage
    } = useSelector(state => state.legacyScreenings);

    const { setting }                            = useSelector(state => state.settings);
    const { showScreeningsImport }               = useSelector(state => state.ui);
    const { lastScreeningImportLogs, isLoading } = useSelector(state => state.logs);

    const config = React.useMemo(() => setting ? (new Setting(setting)).getScreeningsImportConfiguration() : null, [setting]);

    const toggle = tab => {
        if (activeTab !== tab)
            setActiveTab(tab);
    }

    const close = () => {
        dispatch(updateUi({ showScreeningsImport: false }));
    };

    const tktsync = e => {
        dispatch(clearLastScreeningImportLogs());
        dispatch(tktsyncLegacyScreenings((err, imported) => {
            if (!err)
                dispatch(loadScreenings({
                    start_at_gte: moment().startOf('day').toISOString(),
                }));
        }));
    }

    const isUseless = log => {
        if (!log )
            return false;

        return (
            log.inserted?.length === 0 &&
            log.updated?.length === 0 &&
            log.invalid?.length === 0
        );
    };

    const willSyncPlaces = React.useMemo(() => {
        if (!config)
            return false;

        if (config.source === 'kronos')
            return false;

        if (config.source === 'json')
            return config.places_key?.length > 0;

        return true;
    }, [config]);

    const screeningsImportResume = React.useMemo(() => log => (
        <div key={log._id}>
            <div className="bg-dark rounded w-100 p-2">
                <h5>
                    { t('screenings.import.resume') }
                </h5>
                <ul>
                    <li>
                        <b>{ t('screenings.import.nb_deleted', { nb: log.nbDeleted }) }</b>
                    </li>
                    <li>
                        <b>{ t('screenings.import.nb_inserted', { nb: log.inserted?.length }) }</b>
                    </li>
                    <li>
                        <b>{ t('screenings.import.nb_updated', { nb: log.updated?.length }) }</b>
                    </li>
                    <li>
                        <b>{ t('screenings.import.nb_invalid', { nb: log.invalid?.length }) }</b>
                    </li>
                </ul>
            </div>
            {isUseless(log) && (
                <div className="bg-info mt-2 p-2 rounded">
                    <b>
                        { t('screenings.import.was_useless') }
                    </b>
                </div>
            )}
            {(log.inserted?.length > 0) && (
                <div className="bg-info mt-2 p-2">
                    <h5>
                        { t('screenings.import.inserted') }
                    </h5>
                    <ul>
                    {log.inserted.map(screening => (
                        <li key={screening._id} className="screening">
                            <Link to={`/screenings/edit/${screening._id}`}>
                                <b>
                                    {Utils.localized_or_fallback(screening.title, i18n.language)}
                                </b>
                            </Link>
                            - { screening.start_at.format('LLL') }
                        </li>
                    ))}
                    </ul>
                </div>
            )}
            {(log.updated?.length > 0) && (
                <div className="bg-info mt-2 p-2">
                    <h5>
                        { t('screenings.import.updated') }
                    </h5>
                    <ul>
                    {log.updated.map(screening => (
                        <li key={screening._id} className="screening">
                            <Link to={`/screenings/edit/${screening._id}`}>
                                <b>
                                    {Utils.localized_or_fallback(screening.title, i18n.language)}
                                </b>
                            </Link>
                            - { screening.start_at.format('LLL') }
                        </li>
                    ))}
                    </ul>
                </div>
            )}
            {(log.invalid?.length > 0) && (
                <div className="bg-danger mt-2 p-2">
                    <h5>
                        { t('screenings.import.invalid') }
                    </h5>
                    {log.invalid.map((entry, i) => (
                        <div key={i} className="screening">
                            {entry._id ? (
                                <Link to={`/screenings/edit/${entry._id}`}>
                                    <b>
                                        {Utils.localized_or_fallback(entry.title, i18n.language)}
                                    </b>
                                </Link>
                            ) : (
                                <b>
                                    {Utils.localized_or_fallback(entry.title, i18n.language)}
                                </b>
                            )}
                            - { entry.start_at.format('LLL') }
                            <ul>
                                {Object.keys(entry.invalid).map(key => (
                                    <li key={key}>
                                        <b>{ key }</b> : { entry.invalid[key] }
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            )}
        </div>
    ), []);

    const placesImportResume = React.useMemo(() => log => (
        <div key={log._id}>
            <div className="bg-dark rounded w-100 p-2">
                <h5>
                    { t('places.import.resume') }
                </h5>
                <ul>
                    <li>
                        <b>{ t('places.import.nb_deleted', { nb: log.nbDeleted }) }</b>
                    </li>
                    <li>
                        <b>{ t('places.import.nb_inserted', { nb: log.inserted?.length }) }</b>
                    </li>
                    <li>
                        <b>{ t('places.import.nb_updated', { nb: log.updated?.length }) }</b>
                    </li>
                    <li>
                        <b>{ t('places.import.nb_invalid', { nb: log.invalid?.length }) }</b>
                    </li>
                </ul>
            </div>
            {isUseless(log) && (
                <div className="bg-info mt-2 p-2 rounded">
                    <b>
                        { t('places.import.was_useless') }
                    </b>
                </div>
            )}
            {(log.inserted?.length > 0) && (
                <div className="bg-info mt-2 p-2">
                    <h5>
                        { t('places.import.inserted') }
                    </h5>
                    <ul>
                    {log.inserted.map(place => (
                        <li key={place._id} className="place">
                            <Link to={`/places/edit/${place._id}`}>
                                <b> { place.name } </b>
                            </Link>
                            - { place.inema }
                        </li>
                    ))}
                    </ul>
                </div>
            )}
            {(log.updated?.length > 0) && (
                <div className="bg-info mt-2 p-2">
                    <h5>
                        { t('places.import.updated') }
                    </h5>
                    <ul>
                    {log.updated.map(place => (
                        <li key={place._id} className="place">
                            <Link to={`/places/edit/${place._id}`}>
                                <b> { place.name } </b>
                            </Link>
                            - { place.inema }
                        </li>
                    ))}
                    </ul>
                </div>
            )}
            {(log.invalid?.length > 0) && (
                <div className="bg-danger mt-2 p-2">
                    <h5>
                        { t('places.import.invalid') }
                    </h5>
                    {log.invalid.map((entry, i) => (
                        <div key={i} className="place">
                            <li key={entry._id} className="place">
                                {entry._id ? (
                                    <Link to={`/places/edit/${entry._id}`}>
                                        <b> { entry.name } </b>
                                    </Link>
                                    ) : (
                                        <b> { entry.name } </b>
                                    )}
                                - { entry.inema }
                            </li>
                            <ul>
                                {Object.keys(entry.invalid).map(key => (
                                    <li key={key}>
                                        <b>{ key }</b> : { entry.invalid[key] }
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            )}
        </div>
    ), []);

    if (!showScreeningsImport)
        return <div className="ScreeningsImportPanel reduced"></div>;

    const color = hasTktsyncingError ? 'warning' : 'info';

    const placesImportLogs     = lastScreeningImportLogs?.filter(log => log.type === Log.PLACES_IMPORT);
    const screeningsImportLogs = lastScreeningImportLogs?.filter(log => log.type === Log.SCREENINGS_IMPORT);

    return (
        <div className="ScreeningsImportPanel expanded d-block">
            <Button color="danger" size="sm" className='float-right' onClick={close}>
                <i className='fa fa-times'></i>
            </Button>
            <h4 className='border-bottom mt-1'>
                <i className='fa fa-download'></i>&nbsp;{t(`events.tktsync_${config?.source}`)}
            </h4>
            <div className="mb-2">
                <small>
                    { t(`events.tktsync_${config?.source}_desc_screenings`) }
                </small>
            </div>
            {willSyncPlaces && (
                <div className="mb-2">
                    <small>
                        { t(`events.tktsync_${config?.source}_desc_places`) }
                    </small>
                </div>
            )}
            <div className="mb-2">
                {config.import.screenings.delete_future ? (
                    <small> { t(`events.tktsync_delete_future`) } </small>
                ) : config.import.screenings.delete_existing ? (
                    <small> { t(`events.tktsync_delete_existing`) } </small>
                ) : (
                    <small> { t(`events.tktsync_no_delete`) } </small>
                )}
            </div>
            <Button onClick={ tktsync } color={ color } className={`TktSyncButton`}>
                <i className={`fa ${isTktsyncing ? 'fa-spinner fa-spin' : 'fa-download'}`}></i>
                <span>&nbsp;{ t("events.run_tktsync") }</span>
            </Button>

            <hr />

            <div className="d-flex mt-2">
                {(isLoading || isTktsyncing) ? (
                    <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                ) : (
                    <div className="mt-2 w-100">
                        {hasTktsyncingError && (
                            <div className="mt-2 bg-danger p-2 rounded">
                                { tktsyncingErrorMessage || t('screenings.import.an_error_occured') }
                            </div>
                        )}

                        {(lastScreeningImportLogs?.length > 0) && (
                        <>
                            <h4 className="text-center text-underline">
                                { t('screenings.import.imported_at', { date: lastScreeningImportLogs[0].created_at.format('LLL') }) }
                            </h4>
                            <br />
                            <Nav tabs>
                                {(screeningsImportLogs?.length > 0) && (
                                    <NavItem>
                                        <NavLink className={classnames({ active: activeTab === 'screenings' })} onClick={() => { toggle('screenings'); }}>
                                            {t('screenings.import.screenings_tab')}
                                            {(screeningsImportLogs[0].invalid.length > 0) && (
                                                <i className="fa fa-warning ml-2"></i>
                                            )}
                                        </NavLink>
                                    </NavItem>
                                )}
                                {(placesImportLogs?.length > 0) && (
                                    <NavItem>
                                        <NavLink className={classnames({ active: activeTab === 'places' })} onClick={() => { toggle('places'); }}>
                                            {t('places.import.places_tab')}
                                            {(placesImportLogs[0].invalid.length > 0) && (
                                                <i className="fa fa-warning ml-2"></i>
                                            )}
                                        </NavLink>
                                    </NavItem>
                                )}
                            </Nav>
                            <br />
                            <TabContent activeTab={activeTab}>
                                {(screeningsImportLogs?.length > 0) && (
                                    <TabPane tabId="screenings">
                                        { screeningsImportLogs?.map(log => screeningsImportResume(log)) }
                                    </TabPane>
                                )}
                                {(placesImportLogs?.length > 0) && (
                                    <TabPane tabId="places">
                                        { placesImportLogs?.map(log => placesImportResume(log)) }
                                    </TabPane>
                                )}
                            </TabContent>
                        </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ScreeningsImportPanel;
