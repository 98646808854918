import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import { PageTitle } from '../../../components';
import { Utils } from '../../../services/';
import { loadProgrammationGroup, deleteProgrammationGroup } from '../../../redux';

import moment from 'moment';

const ProgrammationsGroupDelete = props => {
    const { t }        = useTranslation();
    const dispatch     = useDispatch();
    const default_lang = Utils.default_lang();

    const goBack = () => {
        window.history.back();
    }

    useEffect(() => {
        const { groupId } = props.match.params;
        dispatch(loadProgrammationGroup(groupId));
    }, []);

    const deleteProgrammation = groupId => {
        dispatch(deleteProgrammationGroup(groupId));
    }

    const {
        programmation_group, isLoading,
        programmation_groupSuccessfullyDeleted
    } = useSelector(state => state.programmation_groups);

    let title = <span>{t("bulidings.programmation_plural")}</span>;
    if (programmation_group)
        title = (
            <span>
                {t("programmations.programmation_group")} |&nbsp;
                {programmation_group.events.length &&
                    <small>
                        {programmation_group.events.length && programmation_group.events.map((e) =>  (
                            e.title[default_lang]
                        )).join(', ')}
                    </small>
                }
            </span>
        );

    const actions =
        <Button onClick={goBack} color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
        </Button>

    return (
        <div className="ProgrammationsGroupDelete">
            <PageTitle icon="th" title={ title } actions={ actions } />

            {programmation_groupSuccessfullyDeleted &&
                <Redirect to="/programmation_groups"/>
            }

            {programmation_group &&
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-warning"></i>&nbsp;{ t('programmations.confirm_delete')}</CardTitle>
                                <CardText tag="div">
                                    <div>
                                        <b>{t("programmations.events")}</b>:&nbsp;
                                        {programmation_group.events.length && programmation_group.events.map((e) =>  (
                                            e.title[default_lang]
                                        )).join(', ')}
                                    </div>
                                    <div>
                                        <b>{t("programmations.start_at")}</b>:&nbsp;
                                        { moment(programmation_group.start_at).format('LL') }
                                    </div>
                                    <div>
                                        <b>{t("programmations.period")}</b>:&nbsp;
                                        { programmation_group.period_nb } { t('common.' + programmation_group.period_type) }
                                    </div>
                                    <Row>
                                        <Col className="text-center">
                                            <Button tag={Link} to="/programmation_groups" color="warning">
                                                <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.cancel')}
                                            </Button>&nbsp;
                                            <Button color="danger" onClick={(e) => deleteProgrammation(programmation_group.id)} disabled={isLoading}>
                                                {isLoading ?
                                                    <span>
                                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                    </span>
                                                    :
                                                    <span>
                                                        <i className="fa fa-trash"></i>&nbsp;
                                                        { t('common.delete')}
                                                    </span>
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </div>
    );
}

const mapDispatchToProps = {}

const mapStateToProps = state => ({
    programmations: state.programmations
})

export default ProgrammationsGroupDelete;
