import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Card, CardBody, CardTitle, CardText, FormGroup, Label } from 'reactstrap';
import { Field, FieldArray } from 'redux-form';
import {
    TranslatableField,
    DaysField,
    HoursField,
    WysiwygField,
    SectionsField
} from '../../../../components';
import ProgrammationTasks from '../ProgrammationTasks/ProgrammationTasks';
import { Utils, hasModule } from '../../../../services';

const ProgrammationForm = props => {
    const { t } = useTranslation();

    const {
        programmation, index, isCreation,
        users, places, pricinglists, bucketlists, sections, activities,
        copyProgrammationOptions, pristine
    } = props;

    const [ bookingMode, setBookingMode ] = useState(() => programmation?.options?.booking_mode);

    const processedPlaces = React.useMemo(() => {
        const result = {};
        if (places.length > 0) {
            places.forEach(p => {
                if (!result[p.cinema])
                    result[p.cinema] = [];
                result[p.cinema].push({
                    name: p.name,
                    _id: p._id
                });
            })
        }
        return result;
    }, [places]);

    return (
        <div>
            <div className="wrapperBody">
                <Row>
                    <Col sm={12} xl={8}>
                        <FormGroup>
                            <Label for="place_id">{`${t("places.place")}`}</Label>
                            <Field required component="select" className="form-control" type="text" name={`programmations[${index}].place_id`} id="place_id">
                                <option key="0" value="">---</option>
                                {
                                    Object.keys(processedPlaces).sort().map((cinema) =>
                                        <optgroup key={cinema} label={cinema}>
                                            {processedPlaces[cinema].sort(Utils.sortOnProperty('name')).map(p =>
                                                <option key={p._id} value={p._id}>{p.name}</option>
                                            )}
                                        </optgroup>
                                    )
                                }
                            </Field>
                        </FormGroup>
                        <FormGroup>
                            <Label for="days">
                                {`${t("programmations.days")}`}&nbsp;/&nbsp;{`${t("programmations.hours")}`}
                            </Label>
                            <Field name={`programmations[${index}].days`} component={DaysField} days={programmation.days} />
                            <Field name={`programmations[${index}].times`} component={HoursField} />
                        </FormGroup>
                    </Col>
                    <Col sm={12} xl={4}>
                        <Card>
                            <CardBody>
                                <CardText tag="div">
                                    <FormGroup row>
                                        <Label for="booking_mode" sm={12}>{t("programmations.booking_mode.title")}</Label>
                                        <Col sm={12}>
                                            <Field component="select" className="form-control" type="text" name={`programmations[${index}].options.booking_mode`} onChange={ e => setBookingMode(e.target.value)}>
                                                <option key="" value="">{`${t("programmations.booking_mode.normal")}`}</option>
                                                {
                                                    Utils.bookingModes().map((mode) => <option key={mode} value={mode}>{t(`programmations.booking_mode.${mode}`)}</option>)
                                                }
                                            </Field>
                                        </Col>
                                    </FormGroup>
                                    {pricinglists && !bookingMode && (
                                        <FormGroup row>
                                            <Label for="pricinglist_id" sm={12}>{t("pricinglists.pricinglist")}</Label>
                                            <Col sm={12}>
                                                <Field component="select" className="form-control" type="text" name={`programmations[${index}].pricinglist_id`} id="pricinglist_id">
                                                    <option key="0" value="">{`${t("pricinglists.pricinglist_choice")}`}</option>
                                                    {
                                                        pricinglists
                                                            .sort(Utils.sortOnProperty('name'))
                                                            .map((p) =>
                                                                <option key={p._id} value={p._id}>{p.name}</option>
                                                            )
                                                    }
                                                </Field>
                                            </Col>
                                        </FormGroup>
                                    )}
                                    {bucketlists && !bookingMode && (
                                        <FormGroup row>
                                            <Label for="bucketlist_id" sm={12}>{t("bucketlists.bucketlist")}</Label>
                                            <Col sm={12}>
                                                <Field component="select" className="form-control" type="text" name={`programmations[${index}].bucketlist_id`} id="bucketlist_id">
                                                    <option key="0" value="">{`${t("bucketlists.bucketlist_choice")}`}</option>
                                                    {
                                                        bucketlists
                                                            .sort(Utils.sortOnProperty('name'))
                                                            .map((p) =>
                                                                <option key={p._id} value={p._id}>{p.name}</option>
                                                            )
                                                    }
                                                </Field>
                                            </Col>
                                        </FormGroup>
                                    )}
                                    {(bookingMode === Utils.BOOKING_MODE_FREE) && (
                                        <FormGroup row>
                                            <Col sm={12}>
                                                <TranslatableField
                                                    name={`programmations[${index}].options.booking_note`}
                                                    id="booking_note"
                                                    autoComplete="booking_note"
                                                    component={WysiwygField}
                                                    optional
                                                    placeholder={t('programmations.booking_mode.free_placeholder')}
                                                />
                                            </Col>
                                        </FormGroup>
                                    )}
                                    {(bookingMode === Utils.BOOKING_MODE_EMAIL) && (
                                        <FormGroup row>
                                            <Col sm={12}>
                                                <TranslatableField
                                                    name={`programmations[${index}].options.booking_note`}
                                                    id="booking_note"
                                                    autoComplete="booking_note"
                                                    component="input"
                                                    placeholder={t('programmations.booking_mode.email_placeholder')}
                                                />
                                            </Col>
                                        </FormGroup>
                                    )}
                                    {(bookingMode === Utils.BOOKING_MODE_OTHER) && (
                                        <FormGroup row>
                                            <Col sm={12}>
                                                <TranslatableField
                                                    name={`programmations[${index}].options.booking_note`}
                                                    id="booking_note"
                                                    autoComplete="booking_note"
                                                    component={WysiwygField}
                                                    optional
                                                    placeholder={t('programmations.booking_mode.other_placeholder')}
                                                />
                                            </Col>
                                        </FormGroup>
                                    )}
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} xl={8}>
                        <Card>
                            <CardBody>
                                <CardText tag="div">
                                    <Row>
                                        <Col sm={12} md={6} lg={3}>
                                            <FormGroup row>
                                                <Label for="week" sm={12}>{t("programmations.week")}</Label>
                                                <Col sm={12}>
                                                    <Field required component="input" className="form-control" type="number" name={`programmations[${index}].options.week`} id="week" autoComplete="week" />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6} lg={3}>
                                            <FormGroup row>
                                                <Label for="section" sm={12}>{t("programmations.section")}</Label>
                                                <Col sm={12}>
                                                    <SectionsField name={`programmations[${index}].options.sections`} className="form-control" sections={sections} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6} lg={3}>
                                            <FormGroup row>
                                                <Label for="_3d" sm={12}>{t("programmations._3d")}</Label>
                                                <Col sm={12}>
                                                    <Field component="select" className="form-control" type="text" name={`programmations[${index}].options._3d`} normalize={Utils.normalizeBoolean}>
                                                        <option key="false" value={false}>{t("common.no")}</option>
                                                        <option key="true" value={true}>{t("common.yes")}</option>
                                                    </Field>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6} lg={3}>
                                            <FormGroup row>
                                                <Label for="break" sm={12}>{t("programmations.break")}</Label>
                                                <Col sm={12}>
                                                    <Field component="select" className="form-control" type="text" name={`programmations[${index}].options.break`} normalize={Utils.normalizeBoolean}>
                                                        <option key="false" value={false}>{t("common.no")}</option>
                                                        <option key="true" value={true}>{t("common.yes")}</option>
                                                    </Field>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={6} lg={3}>
                                            <FormGroup row>
                                                <Label for="version" sm={12}>{t("programmations.version")}</Label>
                                                <Col sm={12}>
                                                    <Field component="input" className="form-control" type="text" name={`programmations[${index}].options.version`} id="version" autoComplete="version" />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6} lg={3}>
                                            <FormGroup row>
                                                <Label for="format" sm={12}>{t("programmations.format")}</Label>
                                                <Col sm={12}>
                                                    <Field component="input" className="form-control" type="text" name={`programmations[${index}].options.format`} id="format" autoComplete="format" />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6} lg={3}>
                                            <FormGroup row>
                                                <Label for="support" sm={12}>{t("programmations.support")}</Label>
                                                <Col sm={12}>
                                                    <Field component="select" className="form-control" type="text" name={`programmations[${index}].options.support`}>
                                                        <option key="0" value="">---</option>
                                                        {
                                                            Utils.programmation_supports().map((s) => <option key={s} value={s}>{t("programmations.support_" + s)}</option>)
                                                        }
                                                    </Field>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6} lg={3}>
                                            <FormGroup row>
                                                <Label for="break" sm={12}>{t("programmations.ignore_on_maccsbox")}</Label>
                                                <Col sm={12}>
                                                    <Field component="select" className="form-control" type="text" name={`programmations[${index}].options.ignore_on_maccsbox`} normalize={Utils.normalizeBoolean}>
                                                        <option key="false" value={false}>{t("common.no")}</option>
                                                        <option key="true" value={true}>{t("common.yes")}</option>
                                                    </Field>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardText>
                                <div className="text-center">
                                    <Button onClick={() => copyProgrammationOptions(index)} color="info" disabled={!pristine}>
                                        <span>
                                            <i className="fa fa-copy"></i>&nbsp;
                                            {t('programmations.copy_options_to_other')}
                                        </span>
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12} xl={4}>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-comments-o"></i>&nbsp;{t("programmations.description")}</CardTitle>
                                <CardText tag="div">
                                    <FormGroup row>
                                        <Col>
                                            <TranslatableField name={`programmations[${index}].description`} component={WysiwygField} />
                                        </Col>
                                    </FormGroup>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {hasModule(Utils.MODULE_RESOURCES) && !isCreation && programmation?.id && activities && activities.length > 0 && users &&
                        <>
                            <CardTitle><i className="fa fa-id-badge"></i>&nbsp;{t("programmations.tasks")}</CardTitle>
                            <CardText tag="div">
                                <FormGroup row>
                                    <Col>
                                        <FieldArray name={`programmations[${index}].tasks`} component={ProgrammationTasks} tasks={programmation.tasks || []} activities={activities} users={users} />
                                    </Col>
                                </FormGroup>
                            </CardText>
                        </>
                        }
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default ProgrammationForm;
