import store from '..';
import { PdfLayouts } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const {
    initialState, resetReducer,
    getReducer, listReducer, updateReducer, duplicateReducer,
    startCreateReducer, createReducer, deleteReducer,
    resetAction, getAction, listAction, updateAction, duplicateAction,
    startCreateAction, createAction, deleteAction
} = createRestSlices(PdfLayouts);

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        resetReducer,
        getReducer, listReducer, updateReducer, duplicateReducer,
        startCreateReducer, createReducer, deleteReducer
    ]);
}

/* Export CRUD actions */
export const resetLayouts      = resetAction;
export const startCreateLayout = startCreateAction;
export const createLayout      = createAction;
export const loadLayout        = getAction;
export const loadLayouts       = listAction;
export const updateLayout      = updateAction;
export const deleteLayout      = deleteAction;
export const duplicateLayout   = duplicateAction;
