import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonGroup, Button, Card, CardBody, CardTitle } from 'reactstrap';
import { Link } from "react-router-dom";
import { Ability, PageTitle, CreateButton, TableButtons } from '../../../components';
import { useTranslation } from 'react-i18next';
import { Utils, Filters } from '../../../services/';
import { loadProgrammationGroups, getProgrammationGroupsLinkedResources } from '../../../redux';
import _ from 'lodash';
import 'react-table-6/react-table.css'
import Table from '../../../components/Table/Table';

import './ProgrammationGroupsList.css';

const ProgrammationList = ({ events_ids }) => {
    const dispatch     = useDispatch();
    const { t }        = useTranslation();
    const default_lang = Utils.default_lang();

    const {
        programmation_groups,
        linkedResources,
    } = useSelector(state => state.programmation_groups);

    const places = linkedResources?.places ?? [];

    useEffect(() => {
        dispatch(getProgrammationGroupsLinkedResources(['places']));
        dispatch(loadProgrammationGroups({ events_ids: events_ids?.join(',') }));
    }, [])

    if (!places || !programmation_groups)
        return null;

    const programmation_groups_data = programmation_groups?.map(group => ({
        ...group,
        days: _.uniq(_.flatten(group.programmations?.map(p => p.days))),
        times: _.uniq(_.flatten(group.programmations?.map(p => p.times))),
        places: _.uniq(group.programmations?.map(p => p.place_id)).join('/')
    }));

    let events = [];
    programmation_groups && programmation_groups.map((p) => {
        events = events.concat(p.events);
    });

    events = _.uniqBy(events, 'id');
    let events_groups = [];
    if (events && events.length) {
        events_groups = _.uniq(events.map((e) => e.type)).map((t) => {
            return {
                label: Utils.event_types(t).label,
                events: _.sortBy(events.filter((e) => e.type === t), `title.${default_lang}`)
            };
        });
    }

    let columns = [];

    if (!events_ids)
        columns.push({
            Header: t("programmations.events"),
            accessor: "events",
            filterMethod: Filters.eventsFilterMethod,
            Filter: Filters.eventsFilter(events_groups),
            filterAll: true,
            Cell: row => (
                <div className="events_cell">
                    <Link to={`/programmation_groups/edit/${row.original.id}`}>
                        <Card>
                            <CardBody style={{
                                "backgroundImage": "url('" + (
                                    row.value && row.value.length &&
                                        row.value[0].posters && row.value[0].posters.length ?
                                        row.value[0].posters[0].url : ""
                                ) + "')",
                                "backgroundPosition": "center center",
                                "backgroundSize": "cover"
                            }}>
                                <div className="title-wrapper">
                                    <CardTitle>{row.original.title[default_lang]}</CardTitle>
                                </div>
                            </CardBody>
                        </Card>
                    </Link>
                </div>
            )
        });

    columns = columns.concat({
        Header: t("programmations.start_at") + ' / ' + t("programmations.stop_at"),
        accessor: "start_at",
        Filter: Filters.dateFilter(true),
        filterMethod: Filters.sameDayDateFilterMethod("start_at"),
        filterAll: true,
        Cell: row => (
            <div className="cell-wrapper">
                <span className="date">
                    <i className="fa fa-calendar"></i>&nbsp;
                    {moment(row.value).format('LL')}
                </span>
                <span className="date">
                    <i className="fa fa-calendar"></i>&nbsp;
                    {moment(row.original.stop_at).format('LL')}
                </span>
            </div>
        )
    }, {
        Header: t("programmations.days"),
        accessor: "days",
        Cell: row => (
            <div className="cell-wrapper">
                {row.original.programmations?.map(p => (
                <div key={p.id} className="row-wrapper">
                    {(p.days && p.days.length === 7) ? (
                        <span className="day">{t('programmations.all_days')}</span>
                    ) : p.days.map((d) =>
                        <span key={d} className="day">
                            {t('common.' + Utils.days(d).label)}
                        </span>
                    )}
                </div>
                ))}
            </div>
        ),
        filterMethod: Filters.daysFilterMethod,
        Filter: Filters.daysFilter,
        filterAll: true
    }, {
        Header: t("programmations.hours"),
        accessor: "times",
        filterAll: true,
        filterMethod: Filters.textFilterMethod('times'),
        Filter: Filters.textFilter,
        Cell: row => (
            <div className="cell-wrapper">
                {row.original.programmations?.map(p => (
                <div key={p.id} className="row-wrapper">
                    {p.times?.map((h) => (
                        <span key={h} className="hour">
                            {h}
                        </span>
                    ))}
                </div>
                ))}
            </div>
        )
    }, {
        Header: t("places.place"),
        accessor: "places",
        filterMethod: Filters.textFilterMethod("places"),
        Filter: Filters.placesFilter(places),
        filterAll: true,
        Cell: row => (
            <div className="cell-wrapper">
                {row.original.programmations?.map(pr => (
                <div key={pr.id} className="row-wrapper">
                    <span className="place">
                        { places.find((p) => p._id === pr.place_id)?.name }<br />
                        <small>
                            { t('programmations.x_screenings', { nb: pr.nb_screenings }) }
                        </small>
                    </span>
                </div>
                ))}
            </div>
        )
    }, {
        Header: t("common.actions"),
        accessor: "id",
        maxWidth: 110,
        className: "text-center",
        filterable: false,
        Cell: row => (
            <ButtonGroup className="TableButtons">
                <Ability owner={row.original.created_by} can="programmations:edit">
                    <Button tag={Link} to={`/programmation_groups/edit/${row.value}`} color="info" title={t("common.edit")} size="sm">
                        <i className="fa fa-edit"></i>
                    </Button>
                </Ability>
                <Ability owner={row.original.created_by} can="programmations:delete">
                    <Button tag={Link} to={`/programmation_groups/delete/${row.value}`} color="danger" title={t("common.delete")} size="sm">
                        <i className="fa fa-trash"></i>
                    </Button>
                </Ability>
            </ButtonGroup>
        )
    });

    const actions =
        <CreateButton
            module="programmations"
            text="programmations.add"
            customPath={`/programmation_groups/new/${events_ids || ""}`}
        />

    const title =
        <span>{t("programmations.programmation_plural")}</span>

    return (
        <div className="ProgrammationGroupsList">
            <PageTitle icon="calendar-plus-o" title={title} actions={actions} />
            <Table id="programmationsTable" data={programmation_groups_data} columnsSortOrder={['start_at', 'days']} columns={columns}/>
        </div>
    );
}

const mapStateToProps = state => ({
    programmation_groups: state.programmation_groups,
    places: state.places
});

export default ProgrammationList;
