import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import ProgrammationGroupsList from './ProgrammationGroupsList/ProgrammationGroupsList';
import ProgrammationGroupEdit from './ProgrammationGroupEdit/ProgrammationGroupEdit';
import ProgrammationGroupCreate from './ProgrammationGroupCreate/ProgrammationGroupCreate';
import ProgrammationGroupDelete from './ProgrammationGroupDelete/ProgrammationGroupDelete';

import './ProgrammationGroups.css';

class ProgrammationGroups extends Component<{}> {
    render() {
        const { match }     = this.props;
        const { isLoading, hasLoadingError, loadingErrorMessage } = this.props.programmations;
        return (
            <div className="ProgrammationGroups">
                {isLoading &&
                    <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
                }
                {hasLoadingError &&
                    <div
                        className="alert alert-danger"
                        dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
                }
                <Route exact path={ match.path } component={ProgrammationGroupsList} />
                <Route exact path={ `${ match.url }/new/:events_ids?` } component={ProgrammationGroupCreate} />
                <Route exact path={ `${ match.url }/edit/:groupId` } component={ProgrammationGroupEdit} />
                <Route exact path={ `${ match.url }/delete/:groupId` } component={ProgrammationGroupDelete} />
            </div>
        );
    }
}

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
    programmations: state.programmations
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProgrammationGroups));
