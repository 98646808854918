import React, { Component } from 'react';
import { useDispatch } from 'react-redux'
import { Field, submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, ButtonGroup, Card, CardBody, CardTitle, CardText, CardFooter, FormGroup, Label } from 'reactstrap';
import { Utils } from '../../../../services/';
import { LangsField } from '../../../../components';

const ProgrammationEvents = props => {
    const { t }        = useTranslation();
    const default_lang = Utils.default_lang();

    const { fields, events, onDelete, i18n } = props;

    const dispatch = useDispatch();
    const remove = i => {
        const { fields, meta: { form }} = props;

        fields.remove(i);
        setTimeout(() => { dispatch(submit(form)); });
    }


    const nb = fields.length;
    return (
        <div className="ProgrammationEvents">
            <Row>
                {fields.map((field, i) =>
                    <Col key={i} xs={nb === 1 ? 12 : 6} sm={nb === 1 ? 12 : 6} md={6} lg={nb === 1 ? 12 : 12 } xl={6}>
                        <Card>
                            <CardBody>
                                <CardTitle className="text-center truncate">{events[i].title[default_lang]}</CardTitle>
                                <CardText tag="div">
                                    <FormGroup row>
                                        <Col>
                                            <Label for="audio">{t("programmations.audio")}</Label>
                                            <LangsField name={`${field}.audio`} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="subtitles">{t("programmations.subtitles")}</Label>
                                            <LangsField multi name={`${field}.subtitles`} />
                                        </Col>
                                    </FormGroup>
                                </CardText>
                            </CardBody>
                            <CardFooter className="text-center" style={{
                                "backgroundImage": "url('" + (events[i].posters && events[i].posters.length ? events[i].posters[0].url : "") + "')",
                                "backgroundPosition": "center center",
                                "backgroundSize": "cover"
                            }}>
                                <ButtonGroup>
                                    <Button color="danger" size="sm" onClick={ () => { remove(i); }}>
                                        <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                    </Button>
                                </ButtonGroup>
                                <Field component="input" type="hidden" className="form-control" name={`${field}.id`} />
                            </CardFooter>
                        </Card>
                    </Col>
                )}
            </Row>
        </div>
    );
}

const mapDispatchToProps = {
    dispatchSubmitForm: (form) => submit(form)
}
const mapStateToProps = state => ({})

export default ProgrammationEvents;
