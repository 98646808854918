import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
    ButtonGroup, Button,
    Row, Col,
    Label,
    InputGroup, InputGroupText, Input,
    Card, CardBody, CardTitle
} from "reactstrap";
const randomColor = require("randomcolor");

const SelectedSeatOption = ({ dragSelectedSeats, zoneSeats, gridCells, onSubmit, onClose, sectorsColors }) => {
    const [zoneCat, setZoneCat] = useState('');
    const [seatType, setSeatType] = useState('');
    const [availableCat] = useState(
        _.without(_.uniq(_.map(gridCells, "zoneCat")), "")
    );
    const { t } = useTranslation();
    const [seatPostionTypeofDragSeats, setSeatPostionTypeofDragSeats] = useState('l2r');
    const [startDragSeatNumber, setStartDragSeatNumber] = useState(1);
    const [skippedSeatNumber, setSkippedSeatNumber] = useState(0);


    const chooseCatName = (index) => {
        setZoneCat(availableCat[index]);
    };

    /**
     * Handle seat type change
     * @param {*} e
     */
    const handleSelect = (e) => {
        setSeatType(e);
    };

    const submitNumberingOptions = () => {
        var postionType = seatPostionTypeofDragSeats
        if (postionType === '') {
            postionType = 'l2r'
        }
        const seats = gridCells;
        const zoneMixpointOfx = _.minBy(zoneSeats, o => o.x);
        const minPointOfY = _.minBy(dragSelectedSeats, o => o.y);
        const maxPointOfY = _.maxBy(dragSelectedSeats, o => o.y);

        var skippedNumber = parseInt(skippedSeatNumber)
        dragSelectedSeats.map((seat) => {
            // debugger
            const xPos = seat.x + zoneMixpointOfx.x
            const yPos = seat.y
            const index = gridCells.findIndex(
                (e) => parseInt(e.x, 10) === xPos && parseInt(e.y, 10) === yPos
            );

            if (index > -1) {
                let tempObj = gridCells[index];
                var emptyColumn = calculateEmptyColumn(zoneSeats,tempObj, postionType === 'l2r' ? 'l2r&t2b' : '')
                if (tempObj !== undefined && tempObj.zoneLabel !== '') {
                    if (postionType === 'l2r') {
                        var seatNumber = parseInt(startDragSeatNumber) + parseInt(seat.y - minPointOfY.y) - emptyColumn + (skippedNumber * (seat.y - (minPointOfY.y+emptyColumn)))
                        tempObj.seat_number = seatNumber
                        tempObj.placing.seat_number = seatNumber
                    } else {
                        const seatNumber = parseInt(startDragSeatNumber) + parseInt(maxPointOfY.y - seat.y) - emptyColumn + (skippedNumber * (maxPointOfY.y - (seat.y+emptyColumn)))
                        tempObj.seat_number = seatNumber
                        tempObj.placing.seat_number = seatNumber
                    }

                    seats[index] = tempObj;
                }
            }
        })
        // setZoneSeats(seats.filter((data) => data.zoneLabel == selectedZone.zoneLabel));
        // setGridCellsTemp(seats)
        onSubmit(gridCells);
    };

    const submitSeatsCats = () => {
        const seats = gridCells;
        const zoneMixpointOfx = _.minBy(zoneSeats, o => o.x);

        dragSelectedSeats.map((seat) => {
            const xPos = seat.x + zoneMixpointOfx.x
            const yPos = seat.y
            const index = gridCells.findIndex(
                (e) => parseInt(e.x, 10) === xPos && parseInt(e.y, 10) === yPos
            );

            if (index > -1) {
                let tempObj = gridCells[index];
                if (tempObj !== undefined && tempObj.zoneLabel !== '') {
                    if (seatType !== '') {
                        tempObj.additional_class_names = seatType;
                    }

                    if (tempObj.zoneCat !== zoneCat && zoneCat !== '') {
                        tempObj.zoneCat = zoneCat;
                    }
                    seats[index] = tempObj;
                }
            }
        })

        onSubmit(gridCells);
    };

    const calculateEmptyColumn = (seatArray, seatObj,positionTypeValue) => {
        var emptyColumnCount = 0
        if (positionTypeValue === 'l2r&t2b' || positionTypeValue === 'l2r&b2t') {
            const minYPointOnZone = _.minBy(seatArray, o => o.y);
            for (var i = seatObj.y - 1; i >= minYPointOnZone.y; i--) {
                const xDataEmpty = seatArray.filter((data) => data.y === i)
                if (xDataEmpty.length === 0) {
                    emptyColumnCount++
                }
            }
        } else {
            const maxYPointOnZone = _.maxBy(seatArray, o => o.y);
            for (var i = seatObj.y + 1; i <= maxYPointOnZone.y; i++) {
                const xDataEmpty = seatArray.filter((data) => data.y === i)
                if (xDataEmpty.length === 0) {
                    emptyColumnCount++
                }
            }
        }
        return emptyColumnCount
    }

    const seatTypes = [
        'classic', 'sofa',
        'sofa-middle', 'sofa-left',
        'sofa-right', 'foldable-seat',
        'wheelchair'
    ];
    return (
        <Col lg={6}>
            <Row>
                <Col xs={12} xl={6}>
                    <h3>
                        {t("places.map.category_and_type")}
                    </h3>
                    <label>
                        {t("places.map.category")}
                    </label>
                    <Row>
                        <Col>
                            <InputGroup>
                                {availableCat.map((data, index) => {
                                    return (
                                        <InputGroupText key={index} className={'zoneOptionMargin'}>
                                            <Button style={{ border: `3px solid ${sectorsColors[data]}` }} onClick={() => chooseCatName(index)}>{data}</Button>
                                        </InputGroupText>
                                    );
                                })}
                            </InputGroup>
                            <Input
                                type="text"
                                value={zoneCat}
                                placeholder={"Zone Seats Category"}
                                onChange={(event) => setZoneCat(event.target.value)}
                            />
                        </Col>
                    </Row>
                    <label>
                        {t("places.map.seattype")}
                    </label>
                    <Row>
                        <Col>
                            <InputGroup>
                                {seatTypes.map((type, index) => (
                                    <InputGroupText key={index} className={'zoneOptionMargin'}>
                                        <Button onClick={() => handleSelect(type)}>
                                            {t(`places.map.${type}`)}
                                        </Button>
                                    </InputGroupText>
                                ))}
                            </InputGroup>
                            <Input
                                type="text"
                                value={seatType}
                                placeholder={"Zone Seats Type"}
                                onChange={(event) => setSeatType(event.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <br />
                            <ButtonGroup>
                                <Button color="success" onClick={submitSeatsCats}>
                                    <span>
                                        <i className="fa fa-save" />&nbsp;
                                        {t("places.map.saveCategoryAndType")}
                                    </span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} xl={6}>
                    <h3>
                        {t("places.map.numbering_options")}
                    </h3>
                    <label>
                        {t("places.map.seatposition")}
                    </label>
                    <Row>
                        <Col>
                            <Button onClick={() => setSeatPostionTypeofDragSeats('l2r')} className={'zoneOptionMargin'}>
                                {t('places.map.l2r')}
                            </Button>

                            <Button onClick={() => setSeatPostionTypeofDragSeats('r2l')} className={'zoneOptionMargin'}>
                                {t('places.map.r2l')}
                            </Button>

                        </Col>
                    </Row>
                    <label>
                        {t("places.map.startSeatNumber")}
                    </label>
                    <Row>
                        <Col>
                            <Input
                                type="number"
                                value={startDragSeatNumber}
                                placeholder={t("places.map.startSeatNumber")}
                                onChange={event => {
                                    setStartDragSeatNumber(event.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                    <label>
                        {t("places.map.skippedSeatNumber")}
                    </label>
                    <Row>
                        <Col>
                            <Input
                                type="number"
                                value={skippedSeatNumber}
                                placeholder={t("places.map.skippedSeatNumber")}
                                onChange={event => {
                                    setSkippedSeatNumber(event.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <br />
                            <ButtonGroup>
                                <Button color="success" onClick={submitNumberingOptions}>
                                    <span>
                                        <i className="fa fa-save" />&nbsp;
                                        {t("places.map.saveNumberingOptions")}
                                    </span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <Button color="warning" onClick={onClose}>
                        <span>
                            <i className="fa fa-arrow-left" />&nbsp;
                            {t("places.map.leaveSelectionOptions")}
                        </span>
                    </Button>
                </Col>
            </Row>
        </Col>
    );
};

export default SelectedSeatOption;
