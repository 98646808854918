import store from '..';
import { ProgrammationGroups, Utils } from '../../services';
import { createRestSlices, applyReducers } from './rest';
import moment from 'moment';

const {
    initialState,
    getReducer, listReducer, updateReducer, duplicateReducer,
    createReducer, deleteReducer, linkedResourcesReducer,
    getAction, listAction, updateAction, duplicateAction,
    createAction, deleteAction, linkedResourcesAction
} = createRestSlices(ProgrammationGroups);

const reducer = (state, action) => {
    switch (action.type) {
        case START_CREATING_PROGRAMMATION_GROUP:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                programmation_groupSuccessfullyDeleted: false,
                programmation_groupSuccessfullyCreated: false,
                programmation_group: {
                    period_nb: 1,
                    period_type: Utils.PERIOD_WEEK,
                    events: [],
                    start_at: moment().startOf('day'),
                    stop_at: moment().endOf('day').add(1, 'week'),
                }
            };
        case CHANGE_PROGRAMMATION_GROUP:
            return {
                ...state,
                programmation_group: {
                    ...state.programmation_group,
                    ...action.programmation_group
                }
            };
        default:
            return state;
    }
};

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        getReducer, listReducer, updateReducer, duplicateReducer,
        createReducer, deleteReducer, linkedResourcesReducer, reducer
    ]);
}

/* Export CRUD actions */
export const createProgrammationGroup              = createAction;
export const loadProgrammationGroup                = getAction;
export const loadProgrammationGroups               = listAction;
export const updateProgrammationGroup              = updateAction;
export const deleteProgrammationGroup              = deleteAction;
export const duplicateProgrammationGroup           = duplicateAction;
export const getProgrammationGroupsLinkedResources = linkedResourcesAction;

const CHANGE_PROGRAMMATION_GROUP = 'kronos/programmation_groups/CHANGE_PROGRAMMATION_GROUP';
export function changeProgrammationGroup(programmation_group) {
    return (dispatch) => {
        return dispatch({ type: CHANGE_PROGRAMMATION_GROUP, programmation_group });
    };
}
const START_CREATING_PROGRAMMATION_GROUP = 'kronos/programmation_groups/START_CREATING_PROGRAMMATION_GROUP';
export function startCreateProgrammationGroup(bucketlists) { return { type: START_CREATING_PROGRAMMATION_GROUP, bucketlists }};
