import BaseModel from './BaseModel';
import Programmation from './Programmation';

/** Class representing a programmation group. */
class ProgrammationGroup extends BaseModel {
    constructor(properties) {
        super(properties);

        this.programmations = (this.programmations || []).map(p => new Programmation(p));
    }

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = super.prepareForUpdate();

        if (prepared.programmations?.length > 0)
            prepared.programmations = prepared.programmations.map(programmation => programmation.prepareForUpdate());

        return prepared;
    }
}

export default ProgrammationGroup;
