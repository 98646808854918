import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'reactstrap';
import { Link } from "react-router-dom";
import matchSorter from 'match-sorter';
import { loadPlaces } from '../../../redux';
import { PageTitle, Table, CopyButton, TableButtons, CreateButton } from '../../../components';
import 'react-table-6/react-table.css'
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import './PlaceList.css';


const PlacesList = () => {
    const { places }              = useSelector(state => state.places);
    const dispatch                = useDispatch();
    const { t }                   = useTranslation();
    const { user }                = useSelector(state => state.auth);
    const { setting }             = useSelector(state => state.settings);
    const hasJsonIntegration      = setting && user?.tkt_instance?.hasJsonIntegration(setting);
    const hasEventivalIntegration = setting && user?.tkt_instance?.hasEventivalIntegration(setting);

    useEffect(() => {
        dispatch(loadPlaces());
    }, []);

    const textFilter = ({ filter, onChange }) =>
        <Input
            type="text"
            onChange={event => onChange(event.target.value)}
        />;
    const textFilterMethod = (key) => (filter, rows) => matchSorter(rows, filter.value, { keys: [key] });

    const tableActions = ["edit"];
    if (!hasEventivalIntegration && !hasJsonIntegration)
        tableActions.push('delete');

    const columns = [{
        Header: t("places.name"),
        accessor: "name",
        filterMethod: textFilterMethod("name"),
        Filter: textFilter,
        filterAll: true,
        Cell: row => (
            <Link to={`/places/edit/${row.original._id}`}>
                <div className="name-cell px-3 py-1 d-flex justify-content-between">
                    {row.value}
                    {(row.original.refs || []).map((ref, i) => (
                        <CopyButton
                            key={ i }
                            size="sm"
                            badge
                            color="info"
                            className="right-20 text-small ml-2"
                            rightComponent={ ref.icone() ? <img src={ref.icone()} className="img rounded w-1 ml-2" /> : null}
                            textToCopy={ ref.display() }
                        />
                    ))}
                </div>
            </Link>
        )
    }, {
        Header: t("places.building"),
        accessor: "cinema",
        filterMethod: textFilterMethod("cinema"),
        Filter: textFilter,
        filterAll: true
    }, {
        Header: t("places.number"),
        accessor: "number",
        filterMethod: textFilterMethod("number"),
        Filter: textFilter,
        filterAll: true,
        width: 200,
        Cell: row => (
            <div className="text-center">{ row.value }</div>
        )
    }, {
        Header: t("places.capacity"),
        accessor: "capacity.total",
        filterable: false,
        width: 100,
        Cell: row => (
            <div className="text-center">
                { row.value }
                { (row.original.capacity?.prm > 0) && (
                    <small>
                        &nbsp;({ row.original.capacity.prm })
                    </small>
                )}
            </div>
        )
    }, {
        Header: t("common.actions"),
        accessor: "_id",
        maxWidth: 110,
        filterable: false,
        Cell: row => (
            <TableButtons
                id={row.value}
                actions={tableActions}
                module="places"
            />
        )
    }];

    const actions =
        <CreateButton
            module={`places`}
            text="places.add"
        />

    const title =
        <span>{t("places.place_plural")}</span>

    return (
        <div className="PlacesList">
            <PageTitle icon="th" title={title} actions={hasEventivalIntegration || hasJsonIntegration ? "" : actions} />
            {hasEventivalIntegration &&
                <div className="alertMessage alert alert-danger"> {t("places.eventival_list_warning")}</div>
            }
            {hasJsonIntegration &&
                <div className="alertMessage alert alert-danger"> {t("places.json_list_warning")}</div>
            }
            <Table
                id="placesTable"
                data={ places }
                columns={columns}
                columnsSortOrder={["cinema", "number", "name"]}
            />
        </div>
    );
}

export default PlacesList;
