import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Badge, Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Utils, hasModule } from '../../../services';
import { Programmation } from '../../../models';
import {
    TranslatableField,
    DateTimePicker,
    EventsField,
    Draggable
} from '../../../components';
import ProgrammationForm from './ProgrammationForm/ProgrammationForm';
import ProgrammationEvents from './ProgrammationEvents/ProgrammationEvents';
import { v4 } from 'uuid';
import _ from 'lodash';
import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import { getProgrammationGroupsLinkedResources, updateProgrammationGroup, changeProgrammationGroup } from '../../../redux';
import 'react-widgets/dist/css/react-widgets.css'

import "./ProgrammationGroupForm.css";

moment.locale('fr');
momentLocalizer();

let ProgrammationGroupForm = ({ handleSubmit, pristine, submitting, isCreation, events_ids }) => {
    const { t, i18n } = useTranslation();
    const dispatch    = useDispatch();

    const [ visibleOptions, showOptions ] = useState([]);
    const { isLoadingOne, programmation_group, linkedResources } = useSelector(state => state.programmation_groups);

    const events        = linkedResources.events ?? [];
    const activities    = linkedResources.activities ?? [];
    const users         = linkedResources.users ?? [];
    const places        = linkedResources.places ?? [];
    const sections      = linkedResources.sections ?? [];
    const pricinglists  = linkedResources.pricinglists ?? [];
    const bucketlists   = linkedResources.bucketlists ?? [];

    const [programmationState, setProgrammationState] = useState({
        period_nb: 1,
        period_type: Utils.PERIOD_WEEK,
        start_at: moment().startOf('day'),
        stop_at: moment().endOf('day').add(1, 'week'),
        events_ids: events_ids ?? null
    });

    useEffect(() => {
        dispatch(getProgrammationGroupsLinkedResources());
        if (events_ids) {
            setState(events_ids, events_ids.split(','));
        }
    }, [])

    useEffect(() => {
        showOptions((programmation_group?.programmations || []).map(p => false))
        if (programmation_group?.id && !programmation_group?.programmations?.length)
            addProgrammation();
    }, [programmation_group?.id])

    const toggleProgrammationOptions = i => {
        const newOptions = [...visibleOptions];
        newOptions[i] = !newOptions[i];
        showOptions(newOptions);
    }

    const addProgrammation = () => {
        dispatch(changeProgrammationGroup({
            programmations: [
                ...programmation_group.programmations,
                new Programmation({
                    id: v4(),
                    place_id: places?.length > 0 ? places[0]._id : null,
                    days: [],
                    times: [],
                    period_nb: 1,
                    period_type: Utils.PERIOD_WEEK,
                    events: [],
                    tasks: [],
                    start_at: moment().startOf('day'),
                    stop_at: moment().endOf('day').add(1, 'week'),
                    description: Utils.default_translatable_fields(),
                    bucketlist_id: (
                        bucketlists?.length === 1 ? bucketlists[0]?._id : null
                    ),
                    options: {
                        "week": 1,
                        "section": null,
                        "_3d": false,
                        "break": false,
                        "version": "",
                        "format": "",
                        "ignore_on_maccsbox": false
                    }
                })
            ]
        }));
    }

    const deleteProgrammation = index => {
        dispatch(changeProgrammationGroup({
            programmations: programmation_group.programmations.toSpliced(index, 1)
        }));
        showOptions(visibleOptions.toSpliced(index, 1));
    }

    const copyProgrammationOptions = index => {
        const base = programmation_group.programmations[index];
        dispatch(changeProgrammationGroup({
            programmations: programmation_group.programmations.map(programmation=> (new Programmation({
                ...programmation,
                options: base.options,
                pricinglist_id: base.pricinglist_id,
                bucketlist_id: base.bucketlist_id
            })))
        }));
    }

    const duplicateProgrammation = index => {
        const programmations = [
            ...programmation_group.programmations,
            new Programmation({
                ...programmation_group.programmations[index],
                id: v4()
            })
        ];
        dispatch(updateProgrammationGroup({
            ...programmation_group,
            programmations
        }));
    }

    const setState = (key, value) => {
        let clonedState = _.cloneDeep(programmationState);
        clonedState[key] = value;
        setProgrammationState(clonedState);
    }

    const goBack = () => {
        window.history.back();
    }

    const onChangePeriodNb = (e) => {
        setState("period_nb", e.target.value, () => { updateStopAt(); });
    }

    const onChangePeriodType = (e) => {
        setState("period_type", e.target.value, () => { updateStopAt(); });
    }

    const onChangeStartAt = (date) => {
        setState("start_at", moment(date), () => { updateStopAt(); });
    }

    const updateStopAt = () => {
        let stop_at = programmationState.start_at.clone().endOf('day');
        const period_nb = programmationState.period_nb;
        switch (programmationState.period_type) {
            case Utils.PERIOD_DAY:
                setState("stop_at", stop_at.add(period_nb - 1, 'days'));
                break;
            case Utils.PERIOD_WEEK:
            case Utils.PERIOD_CINEMA_WEEK:
                setState("stop_at", stop_at.add(7 * period_nb - 1, 'days'));
                break;
            case Utils.PERIOD_MONTH:
                setState("stop_at", stop_at.endOf('month'));
                break;
            case Utils.PERIOD_YEAR:
                setState("stop_at", stop_at.endOf('year'));
                break;
        }
    }

    const period_type = programmationState.period_type;
    let datetimePickerViewMode = 'days';
    if (period_type == Utils.PERIOD_MONTH)
        datetimePickerViewMode = 'months';
    else if (period_type == Utils.PERIOD_YEAR)
        datetimePickerViewMode = 'years';

    const isValidDate = (current) => {
        switch (programmationState.period_type) {
            case Utils.PERIOD_DAY:
            case Utils.PERIOD_WEEK:
                return true;
            case Utils.PERIOD_CINEMA_WEEK:
                return current.day() == 3;
            case Utils.PERIOD_MONTH:
                datetimePickerViewMode = 'months';
                return true;
            case Utils.PERIOD_YEAR:
                datetimePickerViewMode = 'years';
                return true;
        };
    };

    const programmationActions = i => {
        return (
            <div className="d-flex justify-between">
                <ButtonGroup>
                    <Button onClick={() => deleteProgrammation(i)} color="danger" disabled={submitting || isLoadingOne}>
                        {isLoadingOne ?
                            <span>
                                &nbsp;<i className="fa fa-spinner fa-spin"></i>
                            </span>
                            :
                            <span>
                                <i className="fa fa-trash"></i>&nbsp;
                                {t("common.delete")}
                            </span>
                        }
                    </Button>
                </ButtonGroup>
                <ButtonGroup>
                    <Button onClick={() => duplicateProgrammation(i) } color="success" disabled={submitting || isLoadingOne}>
                        {isLoadingOne ?
                            <span>
                                &nbsp;<i className="fa fa-spinner fa-spin"></i>
                            </span>
                            :
                            <span>
                                <i className="fa fa-copy"></i>&nbsp;
                                {t("common.duplicate")}
                            </span>
                        }
                    </Button>
                </ButtonGroup>
            </div>
        );
    };

    if (!programmation_group || !linkedResources)
        return null;

    const programmationResume = programmation => {
        return [
            programmation.options.week ? t('programmations.week', { week: programmation.options.week }) : null,
            programmation.options.break ? <i className="fa fa-pause" /> : null,
            programmation.options._3d ? t('programmations._3d') : null,
            programmation.options.ignore_on_maccsbox ? t('programmations.ignored_on_maccsbox') : null,
            programmation.options.format ? t('programmations.format', { format: programmation.options.format }) : null,
            programmation.options.version ? t('programmations.version', { version: programmation.options.version }) : null,
            programmation.options.support ? t('programmations.support', { support: programmation.options.support }) : null,
        ].filter(s => !!s).map((s, i) => (
            <Badge key={i} color="info" className="mr-1">{s}</Badge>
        ));
    }

    return (
        <Form className="ProgrammationGroupForm" onSubmit={handleSubmit}>
            <Row>
                <Col xs={12}>
                    {programmation_group?.events?.length > 0 &&
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-info-cubes"></i>&nbsp;{t("programmations.title")}</CardTitle>
                                        <CardText tag="div">
                                            {events &&
                                                <FormGroup row>
                                                    <Label for="title" sm={3}>{t("programmations.title")}</Label>
                                                    <Col sm={9}>
                                                        <TranslatableField name="title" placeholder={t("programmations.title_placeholder")} component="input" />
                                                    </Col>
                                                </FormGroup>
                                            }
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {programmationState.events_ids && programmationState.events_ids.length &&
                        <input type="hidden" name="new_event" value={programmationState.events_ids[0]} />
                    }
                    {!programmationState.events_ids &&
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-info-cubes"></i>&nbsp;{t("programmations.events")}</CardTitle>
                                        <CardText tag="div">
                                            <Row>
                                                <Col xs={12} xl={9}>
                                                    <FieldArray name="events" component={ProgrammationEvents} events={programmation_group.events} />
                                                </Col>
                                                <Col xs={12} xl={isCreation ? 12 : 3}>
                                                    {events &&
                                                        <FormGroup row>
                                                            <Label for="new_event" xs={12}>{`${t("programmations.add_event")}`}</Label>
                                                            <Col>
                                                                <EventsField name="new_event" events={events} />
                                                                {!isCreation &&
                                                                    <Button type="submit" color="info" size="sm" disabled={pristine || submitting || isLoadingOne}>
                                                                        {isLoadingOne ?
                                                                            <span> &nbsp;<i className="fa fa-spinner fa-spin"></i> </span>
                                                                            :
                                                                            <span> <i className="fa fa-plus"></i></span>
                                                                        }
                                                                        &nbsp; {t("common.add")}
                                                                    </Button>
                                                                }
                                                            </Col>
                                                        </FormGroup>
                                                    }
                                                </Col>
                                            </Row>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="fa fa-calendar-plus-o"></i>&nbsp;{t("programmations.timing")}</CardTitle>
                            <CardText tag="div">
                                <Row>
                                    <Col xs={12} lg={8}>
                                        <FormGroup>
                                            <Label for="period">{`${t("programmations.period")}`}</Label>
                                            <Row>
                                                <Col xs={12} sm={6}>
                                                    <Field component="input" className="form-control" type="text" name="period_nb" onChange={onChangePeriodNb} />
                                                </Col>
                                                <Col xs={12} sm={6}>
                                                    <Field component="select" className="form-control" onChange={onChangePeriodType} type="text" name="period_type">
                                                        {Utils.periods().map((p) => (
                                                            <option key={p} value={p}>
                                                                {t('common.' + p)}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <FormGroup>
                                            <Label for="start_at">{`${t("programmations.start_at")}`}</Label>
                                            <Field
                                                component={DateTimePicker}
                                                isValidDate={isValidDate}
                                                viewMode={datetimePickerViewMode}
                                                type="text"
                                                name="start_at"
                                                timeFormat={false}
                                                onChange={onChangeStartAt}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {programmation_group?.programmations?.map((programmation, i) => (
                <div key={ i } className={`programmationWrapper ${visibleOptions[i] ? 'highlighted' : ''}`}>
                    <Draggable
                        id={i}
                        onCollapse={ toggleProgrammationOptions }
                        collapsed={ visibleOptions[i] }
                        title={(
                            <div>
                                <div>
                                    <small>
                                        <b>
                                            { programmation.describeDays(t, i18n.language) ?? <i className="fa fa-warning" />}&nbsp;
                                            { programmation.describeTimes(t, i18n.language) }&nbsp;
                                            { t('programmations.in_the_place', {
                                                place: places?.find(p => p._id === programmation.place_id)?.name
                                            })}
                                        </b>
                                    </small>
                                </div>
                                <small>
                                    { programmation.options?.booking_mode ? (
                                        <>
                                            <i className="fa fa-usd" />&nbsp;
                                            { t(`programmations.booking_mode.${programmation.options.booking_mode}`) }
                                        </>
                                    ) : (
                                        <>
                                            <i className="fa fa-usd" />&nbsp;
                                            { pricinglists?.find(p => p._id === programmation.pricinglist_id)?.name || <i className="fa fa-warning" /> }
                                        </>
                                    )}
                                    &nbsp;&nbsp;
                                    <i className="fa fa-battery-half" />&nbsp;
                                    { bucketlists?.find(p => p._id === programmation.bucketlist_id)?.name || <i className="fa fa-warning" /> }
                                    { programmation.tasks?.length > 0 && (
                                        <>
                                            &nbsp;&nbsp;
                                            <i className="fa fa-id-badge" />&nbsp;
                                            { programmation.describeTasks(t, i18n.language) }
                                        </>
                                    )}
                                </small>
                            </div>
                        )}
                        rightContent={ programmationResume(programmation) }
                        actions={ programmationActions(i) }
                        cardbody={
                            <ProgrammationForm
                                index={ i }
                                programmation={ programmation }
                                isCreation={ isCreation }
                                users={ users }
                                places={ places }
                                pricinglists={ pricinglists }
                                bucketlists={ bucketlists }
                                sections={ sections }
                                activities={ activities }
                                copyProgrammationOptions={ copyProgrammationOptions }
                                pristine={ pristine }
                            />
                        }
                    />
                </div>
            ))}

            <Row>
                <Col className="text-center">
                    <ButtonGroup>
                        <Button onClick={goBack} color="warning">
                            <span><i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}</span>
                        </Button>
                        { !isCreation && (
                            <Button onClick={ addProgrammation } color="info">
                                <span><i className="fa fa-plus"></i>&nbsp;{t('programmations.add')}</span>
                            </Button>
                        )}
                        <Button type="submit" color="success" disabled={submitting || isLoadingOne}>
                            <span>
                                <i className={`fa ${isLoadingOne ? 'fa-spinner fa-spin' : 'fa fa-save'}`}></i>&nbsp;{t("common.save")}
                            </span>
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>
    );
}

const mapDispatchToProps = {}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ProgrammationGroupForm = reduxForm({
    form: 'programmationGroupForm', // a unique identifier for this form
    enableReinitialize: true,
    destroyOnUnmount: true
})(ProgrammationGroupForm)

// You have to connect() to any reducers that you wish to connect to yourself
ProgrammationGroupForm = connect(
    state => ({
        // pull initial values from programmations reducer
        initialValues: {
            ...(state.programmation_groups.programmation_group || {})
        },
    }), mapDispatchToProps
)(ProgrammationGroupForm)

export default ProgrammationGroupForm;
