import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import { useTranslation } from "react-i18next";
import ZoneSeat from './ZoneSeat';

const TooltipItem = props => {
    const { item, id, number, color}      = props;
    const [ tooltipOpen, setTooltipOpen ] = useState(false);

    const { t } = useTranslation();

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const borderColor = item.borderColor !== undefined && item.borderColor !== '' ? item.borderColor : '#454545'
    return (
        item.isSeatDisable ?
            <ZoneSeat
                id={ `Tooltip-${id}` }
                seatType = {item.additional_class_names}
                key={ id }
                style={{ borderColor: borderColor }}
            />
        :
        <div style = {{top: -6, left: -10, width: 30, height: 30, position: 'relative'}}>
            <ZoneSeat
                id={ `Tooltip-${id}` }
                key={ id }
                seatType = {item.additional_class_names}
                style={{
                    borderColor: borderColor,
                    backgroundColor: color,
                }}
            >
                { number }
            </ZoneSeat>
            <Tooltip
                placement={ 'top' }
                isOpen={tooltipOpen }
                target={ `Tooltip-${id}` }
                toggle={ toggle }
            >
                <div> { t('places.map.label')} - { item.zoneLabel } </div>
                <div> { t('places.map.category') } - { item.zoneCat }</div>
                <div> { t('places.map.seattype') } - { item.additional_class_names }</div>
                <div> { t('places.map.tooltipmsg') } </div>
            </Tooltip>
        </div>
    );
};

export default TooltipItem;
